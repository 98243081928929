import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Angular = () => {
  return (
    <div className='passbox'>
      <NavBar />
      <div className="pass">
        <h2>Why Do We Need Angular?</h2>
        <p>
          The answer to why we need Angular is as simple as asking for a web development framework that is easy to learn
          and understand, offers swift compilation, and has a wide range of tutorials, documentation, and support for
          newcomers. Since its initial release, the Angular framework has been under continuous development, with every
          new release introducing various new features along with resolving previous issues. Along with such amazing
          benefits of choosing Angular, it is supported by the Google Team, which is another significant advantage. The
          easy addition of third-party tools and components makes it more interesting and easy to use. The Angular CLI
          (Command-Line Interface) is an essential part, and it's easy to set up, use, and comprises simple commands. It
          is used to initialize, develop, and maintain applications in Angular through the terminal. With a large number
          of engineers working on Angular CLI, updates are released for betterment.
        </p>
        <h2>Pros and Cons of Angular</h2>
        <p>
          Every technology that comes to life has its advantages and disadvantages. Similarly, Angular has its own. These
          points define the place of any technology in the whole system. Below are the points that show how and where
          Angular shines and where it doesn’t:
        </p>
        <ul>
          <li>
            <strong>Component-Based:</strong> The whole architecture of Angular, which is component-based, is one of the
            most important factors that separate Angular from others. It provides code of higher quality in a proper
            hierarchy with components as sections with respective functionality.
          </li>
          <li>
            <strong>Testing Friendly:</strong> Having an application comprised of components as elements comes with
            another advantage of being able to test Units separately in an efficient method.
          </li>
        </ul>
        <h2>How Angular Works?</h2>
        <p>
          While there’s a lot to Angular, there are three fundamental concepts that you need to grasp:
        </p>
        <ol>
          <li>
            <strong>Components:</strong> Components are the building blocks of Angular applications. They handle all the
            logic, HTML with Angular code to perform logic, and embed data. Components can communicate with each other
            through different methods like Parent, Child, Sibling relationships or have data parsed through services form
            one component to another.
          </li>
          <li>
            <strong>Services:</strong> Angular services are used to provide functionality to your components. From
            interacting with a REST API to implementing guards for access control, services play a crucial role. Services
            are injected into components using a method called dependency injection. Coupled services and decoupled
            services are two common approaches.
          </li>
          <li>
            <strong>Modules:</strong> Modules are responsible for organizing each of the components in the application.
            This allows for a modularity system, where you’d break up your application into individual modules that are
            all responsible for their own concerns.
          </li>
        </ol>
        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Angular;
