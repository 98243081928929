import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const ETL = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What does ETL stand for?</h2>
        <p>
          ETL stands for Extract, Transform, Load. According to Wikipedia, “Extract, Transform, Load (ETL) is the general procedure of copying data from one or more data sources into a destination system which represents the data differently from the source(s). The ETL process is often used in data warehousing.
        </p>
        <p>
          Data extraction involves extracting data from homogeneous or heterogeneous sources. Data transformation processes data by cleaning and transforming them into a proper storage format/structure for the purposes of querying and analysis. Data loading describes the insertion of data into the target data store, data mart, and data lake or data warehouse. A properly designed ETL system extracts data from the source systems, enforces data quality and consistency standards, conforms data so that separate sources can be used together, and finally delivers data in a presentation-ready format.
        </p>

        <h2>Why perform an ETL?</h2>
        <p>
          To load a data warehouse or data mart regularly (daily/weekly) so that it can serve its purpose of facilitating business analysis. Or move data from files, xml or other sources to a big data lake, data warehouse or data mart.
        </p>

        <h2>How does the ETL process work?</h2>
        <p>
          Extract – ETL developers extract data from one or more systems and/or files and copy it into the data warehouse. Transform – They remove inconsistencies, assemble to a common format, adding missing fields, summarizing detailed data and deriving new fields to store calculated data. Most data in an ETL process is not transformed (about 80% based on a poll of data architects). Load – They map the data to the proper tables and columns, transform and/or load it into the data warehouse.
        </p>

        <h2>When do we need ETL Testing?</h2>
        <p>
          ETL testing can be helpful when setting up a data warehouse for the first time, after data is loaded, after adding a new data source to your existing data warehouse, after a data integration project, after a data migration project, when moving data for any reason, if there are suspected issues with data quality in any of the source systems or the target system, if there are suspected issues with the performance of ETL processes.
        </p>

        <h2>Who is involved in the ETL process?</h2>
        <p>
          There are at least 4 roles involved. They are: Data Analyst, Data Architect, ETL Developer, ETL Tester. The image on the right shows the intertwined roles, tasks and timelines for performing ETL Testing with the sampling method.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default ETL;
