              import React,{useState} from 'react';
              import './fresherscardpages.css';
              import Img from '../img/10798281_19362653.jpg'
              import Img1 from '../img/26459420_future_of_work_concept_illustration.jpg'
              import Img11 from '../img/logo.png';
              import Img8  from '../img/4102879_971.jpg'
              import Img3 from '../img/4559772_4426.jpg'
              import Img4 from '../img/teenager-dressed-white-t-shirt-using-virtual-reality-glasses-with-graph-charts-numbers-lines-technology-concept.jpg'
              import Img5 from '../img/3817830_10902.jpg'
              import { Link} from 'react-router-dom';
              import { Footer } from './footer';
              import Navbar  from './nav';
              import axios from 'axios'
              import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
              import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
              import Img2 from '../img/Screenshot 2024-02-19 122521.png';

               
              export const Fresherscardpages = () => {
              
                const [showPopup, setShowPopup] = useState(false);
                const [brochureUrl, setBrochureUrl] = useState('');
              
                const togglePopup = (data) => {
                  setShowPopup(!showPopup);
                  setBrochureUrl(data);
                };
              
                const handleDownload = (event) => {
                  event.preventDefault();
                 console.log(brochureUrl)
                };
                const [formData, setFormData] = useState({
                  name: '',
                  contact: '',
                  year: '',
                  city: ''
              });
              
              const handleChange = (e) => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
              };
              
              const handleSubmit = (e) => {
                  e.preventDefault();
                  axios.post('https://api.makenow.ashishphotography.co.in/submit', formData)
                      .then(response => {
                          console.log(response.data);
                          // Handle success (e.g., show a success message)
                      })
                      .catch(error => {
                          console.error(error);
                          // Handle error (e.g., show an error message)
                      });
                };

                const [showCard, setShowCard] = useState(true);
                const handleEnrollClick = () => {
                window.location.href = 'https://www.makenow.co.in/';
                };

                return (
                  <div className='cardmarginnn '>
                 
                    <Navbar/>
                    <img  src={Img11} alt='img' className='imagee'/>
                  <div>
                    {/* <div>
                      <Link to="/">
                       <i class="fa-solid fa-arrow-left backbutton"></i>
                      <span className="backtext">Back</span>
                      </Link>
                   </div> */}
    
</div>

                    {showPopup && (
                      <div className="popup">
                        <div className="popup-inner">
                          <h2>Brochure Download Form</h2>
                          <form onSubmit={handleSubmit}>
                              <label>
                                  Name:
                                  <input type="text" name="name" value={formData.name} onChange={handleChange} />
                              </label>
                              <label>
                                  Contact:
                                  <input type="text" name="contact" value={formData.contact} onChange={handleChange} />
                              </label>
                              <label>
                                  Year of Passout:
                                  <input type="text" name="year" value={formData.year} onChange={handleChange} />
                              </label>
                              <label>
                                  City:
                                  <input type="text" name="city" value={formData.city} onChange={handleChange} />
                              </label>
                              <button type="submit">Submit</button>
                              <button type="submit" onClick={handleDownload}>Download</button>

                          </form>
                          <button onClick={togglePopup}>Close</button>
                        </div>
                      </div>
                    )}              
                    <div className='carddssubd d-flex flex-column justify-content-center align-items-center'>
                    <div className='cardrow d-flex   justify-content-center  '>
                      <div class="cardddd cols">
                        <div class="tools">
                          <div class="circle">
                            <span class="red box"></span>
                          </div>
                          <div class="circle">
                            <span class="yellow box"></span>
                          </div>
                          <div class="circle">
                            <span class="green box"></span>
                          </div>
                        </div>
                        <div class="cardddd__content">
                          <img src={Img} alt="Image description"/>
                          <h2  className='typing-demo'>Web Development</h2>
                          <p>Embark on a transformative journey into web development, where code becomes the canvas for your digital creations. 
                          Unleash the power of HTML, CSS, and JavaScript to sculpt the future of the internet and craft immersive user experiences.</p>
                          
                          <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
                          </div>
                          
                          </div>
                      </div>
                      <div class="cardddd cols">
                        <div class="tools">
                          <div class="circle">
                            <span class="red box"></span>
                          </div>
                          <div class="circle">
                            <span class="yellow box"></span>
                          </div>
                          <div class="circle">
                            <span class="green box"></span>
                          </div>
                        </div>
                        <div class="cardddd__content">
                          <img src="https://img.freepik.com/free-vector/illustration-social-media-concept_53876-18141.jpg" alt="Image description"/>
                          <h2  className='typing-demo'>Data Engineer</h2>
                          <p>'Unlock the power of data as a Data Engineer. Master the art of designing robust data pipelines and harnessing the potential of big data, equipping yourself with essential skills for a dynamic career in the field of data engineering.</p>
                          <div>
                          <div className='linkto'> 
                          <button className='button2' onClick={() => togglePopup('2')}>Brochure</button>
                          </div>
                          <div className='linktoo'>
                          <Link to="/dataengineer"><button className='button2'>View course</button></Link>
                          </div>
                          </div>
                    
                          </div>
                      </div>
                      <div class="cardddd cols">
                            <div class="tools">
                              <div class="circle">
                                <span class="red box"></span>
                              </div>
                              <div class="circle">
                                <span class="yellow box"></span>
                              </div>
                              <div class="circle">
                                <span class="green box"></span>
                              </div>
                            </div>
                            <div class="cardddd__content">
                              <img src={Img8} alt="Image description"/>
                              <h2  className='typing-demo'>Python</h2>
                              <p>Discover the gateway to programming excellence with Python – a language prized for its simplicity and power. Join our course to harness Pythons versatility and elevate your coding skills with confidence</p>
                              <div>
                          <div className='linkto'> 
                          <button className='button2' onClick={() => togglePopup('3')}>Brochure</button>
                          </div>
                          <div className='linktoo'>
                          <Link to="/python"><button className='button2'>View course</button></Link>
                          </div>
                          </div>
                    
                              </div>
                      </div>
                    </div>
              
                    <div className='cardrow d-flex  flex-row justify-content-center row mt-3'>
                      <div class="cardddd cols">
                            <div class="tools">
                              <div class="circle">
                                <span class="red box"></span>
                              </div>
                              <div class="circle">
                                <span class="yellow box"></span>
                              </div>
                              <div class="circle">
                                <span class="green box"></span>
                              </div>
                            </div>
                            <div class="cardddd__content">
                              <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/Top_Data_Engineering_Tools_for_2022.jpg" alt="Image description"/>
                              <h2  className='typing-demo'>Dot Net</h2>
                              <p>'Embark on a transformative journey into the world of software development with our comprehensive .NET course. Gain mastery over the versatile .NET framework, empowering you to build robust, scalable applications for web, desktop, and mobile platforms.'</p>
                              <div>
                          <div className='linkto'> 
                          <button className='button2' onClick={() => togglePopup('4')}>Brochure</button>
                          </div>
                          <div className='linktoo'>
                          <Link to="/dotnet"><button className='button2'>View course</button></Link>
                          </div>
                          </div>
                          
                              </div>
                      </div>
                      <div class="cardddd cols">
                            <div class="tools">
                              <div class="circle">
                                <span class="red box"></span>
                              </div>
                              <div class="circle">
                                <span class="yellow box"></span>
                              </div>
                              <div class="circle">
                                <span class="green box"></span>
                              </div>
                            </div>
                            <div class="cardddd__content">
                              <img src={Img4} alt="Image description"/>
                              <h2  className='typing-demo'>Software Immersive Bootcamp</h2>
                              <p>'Elevate your coding skills with our Software Immersive Bootcamp - an intensive, hands-on program designed to immerse you in real-world projects, industry best practices, and mentorship, ensuring you emerge as a proficient and job-ready software developer.'</p>
                              <div>
                          <div className='linkto'> 
                          <button className='button2' onClick={() => togglePopup('5')}>Brochure</button>
                          </div>
                          <div className='linktoo'>
                          <Link to="/software"><button className='button2'>View course</button></Link>
                          </div>
                          </div>
                          
                              </div>
                      </div>
                      <div class="cardddd cols">
                            <div class="tools">
                              <div class="circle">
                                <span class="red box"></span>
                              </div>
                              <div class="circle">
                                <span class="yellow box"></span>
                              </div>
                              <div class="circle">
                                <span class="green box"></span>
                              </div>
                            </div>
                            <div class="cardddd__content">
                              <img src="https://greatnusa.com/wp-content/uploads/2023/01/strategi-digital-marketing.jpg" alt="Image description"/>
                              <h2  className='typing-demo'>Digital Marketing</h2>
                              <p>'Explore the dynamic world of Digital Marketing and unlock the skills to strategically connect, engage, and influence audiences online. Elevate your career with a course that blends creativity and analytics for impactful digital campaigns.'</p>
                              <div>
                          <div className='linkto'> 
                          <button className='button2' onClick={() => togglePopup('6')}>Brochure</button>
                          </div>
                          <div className='linktoo'>
                          <Link to="/digitalmarketing"><button className='button2'>View course</button></Link>
                          </div>
                          </div>
                      
                              </div>
                      </div>
                    </div>
                    </div>
                    <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img2} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
                    <Footer/>
                  </div>
                );
              };