import './aboutus.css';
import './fonts.css';
import Navbar from './nav';
import {Footer} from './footer'; // Assuming you have a Footer component
import React, { useEffect,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
import Img2 from '../img/Screenshot 2024-02-19 122521.png';
import Img1 from '../img/logo.png';
import image from '../img/pngtree-team-work-meeting-illustration-image-png-image_8416291.png';
import { Link } from 'react-router-dom';



export default function App() {
  function fok() {
    var j = document.getElementById("arr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/premium/png-64-thumb/chevron-arrow-3883460-3231250.png)";
  }

  function kof() {
    var j = document.getElementById("arr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/free/png-64/right-arrow-1438234-1216195.png)";
  }

  function gok() {
    let j = document.getElementById("brr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/premium/png-64-thumb/chevron-arrow-3883460-3231250.png)";
  }

  function kog() {
    let j = document.getElementById("brr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/free/png-64/right-arrow-1438234-1216195.png)";
  }

  function hok() {
    let j = document.getElementById("crr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/premium/png-64-thumb/chevron-arrow-3883460-3231250.png)";
  }

  function koh() {
    let j = document.getElementById("crr");
    j.style.backgroundImage =
      "url(https://cdn.iconscout.com/icon/free/png-64/right-arrow-1438234-1216195.png)";
  }

  window.onscroll = function () {
    jet();
  };

  function jet() {
    var ilake = document.getElementById("head");
    if (ilake) {
        ilake.style.top = "0px";
        ilake.style.position = "sticky";
    }
}


  window.addEventListener("scroll", () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var wnd = window.innerHeight;
      var rtop = reveals[i].getBoundingClientRect().top;
      var rpoint = 100;

      if (rtop < wnd - rpoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  });
  const [showCard, setShowCard] = useState(true);
  const handleEnrollClick = () => {
    window.location.href = 'https://www.makenow.co.in/';
  };
  return (
    <div className="App">
     <img  src={Img1} alt='img' className='imagee'/>
      <Navbar />
      <main>
        <div id="front">
          <h1 style={{ textAlign: "center" }}>MAKENOW ACADEMY</h1>
          <img src={image} alt="font" />


          <p>
          MakeNow Academy is a cutting-edge institution dedicated to fostering innovation, creativity, and practical skill development in various domains such as technology, business, design, and more. Founded on the principle of empowering individuals to make an impact in today's fast-paced world, MakeNow Academy offers a range of programs tailored to meet the needs of aspiring professionals, entrepreneurs, and lifelong learners alike.At MakeNow Academy, the emphasis is on hands-on learning experiences that bridge the gap between theory and practice. Whether you're looking to sharpen your programming skills, enhance your digital marketing expertise, or dive into the realms of artificial intelligence and data science, MakeNow Academy provides comprehensive courses designed and taught by industry experts.
          </p>
        </div>

        <div id="first" className="reveal">
          <img
            src="https://cdni.iconscout.com/illustration/premium/thumb/two-men-stand-at-pc-explain-information-to-girl-2706749-2268243.png"
            alt=""
          />
          <div>
            <h1>OUR OFFERS</h1>
            <p>
            Moreover, MakeNow Academy places a strong emphasis on staying abreast of the latest trends and technologies shaping the future landscape of various industries. The curriculum is continuously updated to reflect emerging developments, ensuring that students receive relevant and up-to-date knowledge that is directly applicable to their professional pursuits.
            </p>
            <h2>OUR TEACHING (100%)</h2>
            <div className="comm">
              <div id="comm1"></div>
            </div>
            <h2>OUR SUPPORT (100%)</h2>
            <div className="comm">
              <div id="comm2"></div>
            </div>
            <h2>OUR OPPERTUNITIES (100%)</h2>
            <div className="comm">
              <div id="comm3"></div>
            </div>
          </div>
        </div>

        <div id="fourth" className="reveal">
          <h2>RESOURCES</h2>
          <p >In addition to its core curriculum, MakeNow Academy offers a range of supplementary resources and support services to help students maximize their learning outcomes. From mentorship programs and career counseling to networking events and internship opportunities, MakeNow Academy is committed to equipping students with the tools, resources, and connections they need to thrive in their chosen fields.</p>
          <div id="fourth_cards">
            <div>
            <Link to='/dataengineer'>
              <img
                src="https://cdn.iconscout.com/icon/premium/png-64-thumb/data-analysis-27-681042.png"
                alt=" "
              />
              <p>DATA ENGINEERING</p>
              </Link>
            </div>
            <div>
            <Link to='/digitalmarketing'>
              <img
                src="https://cdn.iconscout.com/icon/premium/png-64-thumb/ui-ux-designer-2755964-2289563.png"
                alt=" "
              />
              <p>DIGITAL MARKETING</p>
              </Link>
            </div>
            <div>
            <Link to='/webdevelopment'>
              <img
                src="https://cdn.iconscout.com/icon/premium/png-64-thumb/web-development-3-478143.png"
                alt=" "
              />
              <p>WEB DEVELOPEMENT</p>
              </Link>
            </div>
            <div>
            <Link to='/dotnet'>
              <img
                src="https://cdn.iconscout.com/icon/premium/png-64-thumb/qa-testing-3919162-3246433.png"
                alt=" "
              />
              <p>DOT NET</p>
              </Link>
            </div>
            <div>
            <Link to='/fresherscardpages'>
              <img
                src="https://cdn.iconscout.com/icon/premium/png-64-thumb/team-135-386667.png"
                alt=" "
              />
              <p>MORE FROM US</p>
              </Link>
            </div>
          </div>
        </div>

        <div id="second" className="reveal">
  <div className="containeros">
  <img src='https://cdni.iconscout.com/illustration/premium/thumb/computer-4604370-3806641.png?f=webp' alt="fonts" className="imm" />
  <p>One of the distinguishing features of MakeNow Academy is its commitment to fostering a collaborative learning environment. Students have the opportunity to engage with peers from diverse backgrounds, exchanging ideas, sharing insights, and working together on real-world projects. This collaborative approach not only enriches the learning experience but also cultivates essential teamwork and communication skills vital for success in today's interconnected world.</p>
    </div>
</div>


<div id="third" className="reveal">
  <h6 style={{ textAlign: "center" }}>OUR PROVISION</h6>
  <h6 style={{ textAlign: "center" }}>
    Overall, MakeNow Academy serves as a dynamic hub for individuals passionate about leveraging technology, innovation, and entrepreneurship to drive positive change in the world. By providing a transformative learning experience that combines practical skills development, collaborative learning, and ongoing support, MakeNow Academy empowers its students to turn their ideas into reality and make a meaningful impact in their respective industries and communities.
  </h6>
  <div id="third_cards">
    <div>
      <h2>End to End Solutions and Services Guaranteed</h2>
      <p>
        MakeNow Academy offers comprehensive end-to-end solutions and services, ensuring that students receive holistic support throughout their learning journey. From expert guidance and mentorship to cutting-edge resources and hands-on projects, we guarantee a seamless experience tailored to your needs.
      </p>
    </div>
    <div>
      <h2>Ahead of The Curve We Future-proof Your IT</h2>
      <p>
        At MakeNow Academy, we are committed to keeping you ahead of the curve by future-proofing your IT skills. Our curriculum is meticulously designed to incorporate the latest technologies, trends, and industry practices, ensuring that you remain competitive and adaptable in today's rapidly evolving digital landscape.
      </p>
    </div>
    <div>
      <h2>Experience Certainty Every Project Executed Successfully</h2>
      <p>
        With MakeNow Academy, you can experience certainty in every project you undertake. Our hands-on approach, combined with real-world case studies and practical exercises, ensures that you gain the confidence and expertise needed to execute projects successfully. From ideation to execution, we empower you to achieve your goals with certainty.
      </p>
    </div>
  </div>
</div>

      </main>
      <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img2} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  );
}
