import React, { useEffect,useState } from 'react';
import './firstpage.css'; // Import your SCSS styles
import feather from 'feather-icons';
import { Footer } from './footer';
import Img4 from '../img/Untitled design n.png';
import Img2 from '../img/Screenshot 2024-02-19 122521.png';
import Img1 from '../img/logo.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import './fonts.css';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
import { Homee } from './homee';

export const Firstpage = () => {
    useEffect(() => {
        feather.replace(); // Call feather.replace() to replace feather icons
      }, []);
      useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          once: true, // Animation only happens once
        });
      }, []); 

      const [showCard, setShowCard] = useState(true);

    //   useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowCard(false);
    //     }, 15000);
    
    //     return () => clearTimeout(timer);
    // },[]);

      const handleEnrollClick = () => {
        window.location.href = 'https://www.makenow.co.in/';
    };
      return (
       <div className='pagee'>
        <img  src={Img1} alt='img' className='imagee'/>
           <Homee/>
          <div className='main-sub-heading'>
              <p>The IT Career of Your Dreams Starts Here!</p>
          </div>
          <div class=" d-flex flex-row justify-content-center">
              <div className='my-button'>
              <Link to='/fresherscardpages' class="my-button__text">Enter</Link>
              </div>
          </div>
          <div className='timelineimg d-flex flex-row justify-content-center mt-5'>
              <img src={Img4} alt="" />
            </div>
          <div className='page1'>
          <div>
            <section className="timer-section">
              <div className="containerr">
                <div className="timer-container">
                  <ul className="timer">
                    <li className="timer-step" >
                      <span>1</span>
                      <div className="timer-content">
                        <h3>Program Features</h3>
                        <p>
                          Comprehensive Curriculum<br />
                          Hands-on-Projects<br />
                          Personalized Career Support<br />
                          Flexible Learning Options
                        </p>

                      </div>
                    </li>
                    <li className="timer-step" data-aos="fade-up" >
                      <span>2</span>
                      <div className="timer-content">
                        <h3>Statistics</h3>
                        <p>
                          95% Graduates placed in Jobs<br/>
                          6Lpa Average Starting Salary <br/>
                          500+ Partner Companies
                        </p>
                      </div>
                    </li>
                    <li className="timer-step" >
                      <span>3</span>
                      <div className="timer-content">
                        <h3>Guarantee Details</h3>
                        <p>
                          8-Months Job Placement Guarantee<br/>
                          Online Classes(9am to 6pm)<br/>
                          Ongoing Support
                        </p>
                      </div>
                    </li>
                    <li className="timer-step" data-aos="fade-up">
                      <span>4</span>
                      <div className="timer-content">
                        <h3>Payments</h3>
                        <p>
                          Easy EMI Plans<br/>
                          Pay after Payments<br/>
                          Why late call us now?
                        </p>
                      </div>
                    </li>
                  
                  </ul>
                </div>
              </div>
           </section>
          </div>
          </div>
          <div className='p'>
          <div className="containerrr">
              <div className="card-left">
                <div className="card-image">
                  <img src='https://thumbs.dreamstime.com/b/web-development-dark-digital-background-blue-color-text-36647499.jpg' alt="Card Image" />
                </div>
                <div className="card-text">
                  <h3><a href="https://www.makenow.co.in/">WEB DEVELOPMENT</a></h3>
                </div>
              </div>
              <div className="card-top">
                <div className="card-image">
                  <img src="https://img.freepik.com/free-vector/artificial-intelligence-isometric-icon-server-room-datacenter-database-concept_39422-772.jpg" alt="Card Image" />
                </div>
                <div className="card-text">
                <h3><a href="https://www.makenow.co.in/">DATA ENGINEERING</a></h3>
                </div>
              </div>
              <div className="card-right">
                <div className="card-image">
                  <img src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg" alt="Card Image" />
                </div>
                <div className="card-text">
                <h3><a href="https://www.makenow.co.in/">PYTHON</a></h3>
                </div>
              </div>
              <div className="card-right">
                <div className="card-image">
                  <img src="https://media.istockphoto.com/id/1500238408/photo/program-code-development-icon-on-a-digital-lcd-display-with-reflection.webp?b=1&s=170667a&w=0&k=20&c=CfaVabgMcwwc-ijzVAxNs_Sz6q3JVPJnlQ-Py-dpuAQ=" alt="Card Image" />
                </div>
                <div className="card-text">
                <h3><a href="https://www.makenow.co.in/">DOT NET</a></h3>
                </div>
              </div>
              <div className="card-top">
                <div className="card-image">
                  <img src="https://images.spiceworks.com/wp-content/uploads/2023/05/25061046/Shutterstock_2060615657.jpg" alt="Card Image" />
                </div>
                <div className="card-text">
                <h3><a href="https://www.makenow.co.in/">SOFTWARE IMMERSIVE BOOTCAMP</a></h3>
                </div>
              </div>
              <div className="card-bottom">
                <div className="card-image">
                  <img src="https://greatnusa.com/wp-content/uploads/2023/01/strategi-digital-marketing.jpg" alt="Card Image" />
                </div>
                <div className="card-text">
                <h3><a href="https://www.makenow.co.in/">DIGITAL MARKETING</a></h3>
                </div>
              </div>
            </div>
          
          </div>
          <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img2} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
        <Footer/>
       </div>
      );
    }
