import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const DataScience = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is DATA SCIENCE?</h2>
        <p>
          Data science is the process of using data to find solutions or to predict outcomes for a problem statement.
        </p>

        <h2>HOW Data science is affective in our day activities?</h2>
        <p>
          To better understand data science, let's see how it's effective in our day-to-day activities. It's a Monday morning, and I have to get to the office before my meeting starts. So I quickly open up Uber and look for cabs, but there’s something unusual: the fare reads comparatively higher at this hour of the day. Why does this happen? Well, obviously because Monday mornings are packed cars and everyone is rushing off to work. The high demand for cabs leads to an increase in cab fares. We all know this, but how is all of this implemented? Data science is at the heart of Uber's pricing algorithm. The surge pricing algorithm ensures that their passengers always get a ride when they need one, even if it comes at the cost of inflated prices.
        </p>
        <p>
          Uber implements data science to find out which neighborhoods will be the busiest so that it can activate surge pricing to get more drivers on the road. In this manner, Uber maximizes the number of rides it can provide and hence benefits from this. Uber's surge pricing algorithm uses data science.
        </p>

        <h2>Why do you want to learn data science?</h2>
        <p>
          <strong>5 valuable reasons to pursue data science as a career:</strong>
        </p>
        <ul>
          <li>Great career trajectory with data science – Data scientists bring tons of value to organizations and are the most sought after roles in today’s scenario and will be in the future.</li>
          <li>Great potential to branch out with different options – You can choose to branch out as a data engineer, an analyst, or an ML engineer, or even a data science manager.</li>
          <li>Highest salary takeaway quotient – As a Data scientist, you can expect to take away a great salary package. Usual data scientists are paid great salaries, sometimes much above the normal market standards due to the critical roles and responsibilities.</li>
          <li>Become a decision-maker – Not every job opportunity will give you the power to make informed business decisions. For a data scientist, that is the core responsibility. That is how you kick start. The credibility will always be rewarded because of the lack of talent pool in the ecosystem.</li>
          <li>Less competitive because it is a highly analytical role – Competition is less, but demand is not. With a limited talent pool, there is always a challenge for businesses to hire in these roles. Once you join in, you become a decision-maker and face less competition from your organization’s peers for you having a unique skill set.</li>
        </ul>

        <h2>What is the need for Data Science?</h2>
        <p>
          The reason why we need data science is the ability to process and interpret data. This enables companies to make informed decisions around growth, optimization, and performance. Demand for skilled data scientists is on the rise now and in the next decade. For example, machine learning is now being used to make sense of every kind of data – big or small. Data metrics are driving every business decision. The job market scenario for data scientists will grow to almost 11.5M by 2026 [U.S Bureau of Labor Statistics]. Companies are busy ramping up their data science workforce to enable higher efficiency and planning.
        </p>

        <h2>What is Data Science useful for?</h2>
        <p>
          Data science is a process that empowers better business decision-making through interpreting, modeling, and deployment. This helps in visualizing data that is understandable for business stakeholders to build future roadmaps and trajectories. Implementing Data Science for businesses is now a mandate for any business looking to grow.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default DataScience;
