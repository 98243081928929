import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const CyberSecurity = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Cyber Security?</h2>
        <p>
          Cybersecurity is the protection of internet-connected systems such as hardware, software and data from cyberthreats. The practice is used by individuals and enterprises to protect against unauthorized access to data centers and other computerized systems.
        </p>
        <h2>Why do we need Cyber Security?</h2>
        <p>
          The range of operations of cyber security involves protecting information and systems from major cyber threats. These threats take many forms. As a result, keeping pace with cyber security strategy and operations can be a challenge, particularly in government and enterprise networks where, in their most innovative form, cyber threats often take aim at secret, political and military assets of a nation, or its people.
        </p>
        <h3>Types of Cyber Security</h3>
        <p>The different types of cybersecurity include:</p>
        <ul>
          <li>
            <strong>Application Security:</strong> Application security is the implementation of various defenses within business software and services to protect against a range of different threats.
          </li>
          <li>
            <strong>Data Security:</strong> Data security involves implementing strong data storage systems that are specifically designed to secure information while it is being stored and while it is in transit.
          </li>
          <li>
            <strong>Network Security:</strong> Network security focuses on protecting a business from both external and internal threats by implementing hardware and software systems that are specifically designed to protect a business’ network and infrastructure from misuse, disruptions, and unauthorized access.
          </li>
          <li>
            <strong>Mobile Security:</strong> Businesses that use mobile devices, such as cell phones, laptops, and tablets, should use mobile security measures to protect the information that is being stored on those devices from a range of different threats.
          </li>
          <li>
            <strong>Cloud Security:</strong> Most cloud applications and system—AWS, Google, Microsoft, etc. use cloud security measures to protect users against various threats.
          </li>
        </ul>
        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
          <button className="button">
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default CyberSecurity;
