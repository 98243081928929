import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Pythonsel = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Python Selenium?</h2>
        <p>
          Python Selenium is a powerful tool for controlling web browsers through programs and performing browser automation. It is functional for all browsers, works on all major OS and its scripts are written in various languages i.e. Python, Java, C#, etc., we will be working with Python. Selenium Tutorial covers all topics such as – Web Driver, Web Element, and Unit Testing with selenium. This Python Selenium Tutorial covers Selenium from basics to advanced and professional uses.
        </p>

        <h2>Why learn Python Selenium?</h2>
        <p>
          There are several reasons to learn Python Selenium:
          <ul>
            <li>Open Source and Portable: Selenium is an open-source and portable Web testing Framework.</li>
            <li>Combination of tool and DSL: Selenium is a combination of tools and DSL (Domain Specific Language) to carry out various types of tests.</li>
            <li>Easier to understand and implement: Selenium commands are categorized into different classes, making it easier to understand and implement.</li>
            <li>Less burden and stress for testers: The amount of time required to do testing repeated test scenarios on each and every new build is reduced to almost zero, reducing the burden on testers.</li>
            <li>Cost reduction for Business Clients: Automation testing tools like Selenium save time and cost for businesses by reducing manual testing efforts.</li>
          </ul>
        </p>

        <h2>What are the topics for Python Selenium?</h2>
        <p>
          Some of the topics covered in Python Selenium are:
          <ul>
            <li>Selenium Python Introduction and Installation.</li>
            <li>Navigating links using the get method.</li>
            <li>Interacting with Webpage.</li>
            <li>Locating single elements.</li>
            <li>Locating multiple elements.</li>
            <li>Locator Strategies – Selenium Python.</li>
            <li>Writing Tests using Selenium Python.</li>
          </ul>
        </p>

        <h2>Is Python Selenium good?</h2>
        <p>
          Python Selenium is a powerful tool for web automation and testing. It provides an easy-to-use interface and is widely used for automated testing of web applications. Python Selenium is considered better than Java for automating simple tasks and writing tests for broad applications.
        </p>

        <h2>How Python Selenium works internally?</h2>
        <p>
          To understand how Python Selenium automation works internally, we have to first understand the Selenium Web Driver architecture. The architecture consists of four layers:
          <ol>
            <li><strong>Selenium Client Library/language bindings</strong></li>
            <li><strong>JSON Wire Protocol over HTTP</strong></li>
            <li><strong>Browser devices</strong></li>
            <li><strong>Browsers</strong></li>
          </ol>
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Pythonsel;
