import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const DigitalMarketing = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Digital Marketing?</h2>
        <p>
          Digital marketing is basically internet marketing. It is also referred to as advertising and delivering through digital media channels. The channels can be social media platforms, mobile platforms, email or web applications, search engines, and websites. In simple words, “digital marketing is any form of marketing product or services that involves electronic devices.” This is a major reason why it's been around for more than decades.
        </p>

        <h2>Why Digital Marketing?</h2>
        <p>
          The major reason would be marketing has a lot of options. It is always related to the strategies associated with it, and also you can get creative and experiment with every little aspect of the marketing campaign. But when you compare this with the traditional way of marketing, this is impossible. The tasks that you can complete online cannot be done with the help of traditional marketing. Traditional marketing firms would be printing ads, phone communication, and also physical marketing. If we want to start a business and we want to promote them, we cannot go to everybody's house and knock on their doors until I’m going to start this project or I’m going to work on this. Nobody in the recent days does that. So one platform that you can actually put across your views, put across your ideas about what you are going to be doing is digital marketing.
        </p>

        <h2>Why is Digital Marketing Important?</h2>
        <p>
          Digital marketing helps you reach a larger audience than you could through traditional methods and target the prospects who are most likely to buy your product or service. Additionally, it's often more cost-effective than traditional advertising and enables you to measure success on a daily basis and pivot as you see fit.
        </p>
        <p>
          There are a few major benefits of digital marketing:
        </p>
        <ol>
          <li>You can focus your efforts on only the prospects most likely to purchase your product or service.</li>
          <li>It's more cost-effective than outbound marketing methods.</li>
          <li>Digital marketing evens the playing field within your industry and allows you to compete with bigger brands.</li>
          <li>Digital marketing is measurable.</li>
          <li>It’s easier to adapt and change a digital marketing strategy.</li>
          <li>Digital marketing can improve your conversion rate and the quality of your leads.</li>
          <li>You can engage audiences at every stage with digital marketing.</li>
        </ol>

        <h2>How does a Business Define Digital Marketing?</h2>
        <p>
          At this stage, digital marketing is vital for your business and brand awareness. It seems like every other brand has a website. And if they don't, they at least have a social media presence or digital ad strategy. Digital content and marketing is so common that consumers now expect and rely on it as a way to learn about brands. Because digital marketing has so many options and strategies associated with it, you can get creative and experiment with a variety of marketing tactics on a budget.
        </p>
        <p>
          Digital marketing is defined by the use of numerous digital tactics and channels to connect with customers where they spend much of their time: online. The best digital marketers have a clear picture of how each digital marketing campaign supports their overarching goals. And depending on the goals of their marketing strategy, marketers can support a larger campaign through the free and paid channels at their disposal.
        </p>

        <h2>What Does a Digital Marketer Do?</h2>
        <p>
          Digital marketers are in charge of driving brand awareness and lead generation through all the digital channels both free and paid that are at a company's disposal. These channels include social media, the company's own website, search engine rankings, email, display advertising, and the company's blog.
        </p>
        <p>
          The digital marketer usually focuses on a different key performance indicator (KPI) for each channel so they can properly measure the company's performance across each one. A digital marketer who's in charge of SEO, for example, measures their website's "organic traffic." In small companies, one generalist might own many of the digital marketing tactics described above at the same time. In larger companies, these tactics have multiple specialists that each focus on just one or two of the brand's digital channels.
        </p>

        <h2>Does Digital Marketing Work for All Businesses?</h2>
        <p>
          Digital marketing can work for any business in any industry. Regardless of what your company sells, digital marketing still involves building out buyer personas to identify your audience's needs and creating valuable online content. However, that's not to say all businesses should implement a digital marketing strategy in the same way.
        </p>
        <h3>B2B Digital Marketing:</h3>
        <p>
          If your company is business-to-business (B2B), your digital marketing efforts are likely to be centered around online lead generation, with the end goal being for someone to speak to a salesperson. For that reason, the role of your marketing strategy is to attract and convert the highest quality leads for your salespeople via your website and supporting digital channels. Beyond your website, you'll probably choose to focus your efforts on business-focused channels like LinkedIn where your demographic is spending their time online.
        </p>
        <h3>B2C Digital Marketing:</h3>
        <p>
          If your company is business-to-consumer (B2C), depending on the price point of your products, it's likely that the goal of your digital marketing efforts is to attract people to your website and have them become customers without ever needing to speak to a salesperson. For that reason, you're probably less likely to focus on ‘leads' in their traditional sense, and more likely to focus on building an accelerated buyer's journey, from the moment someone lands on your website, to the moment that they make a purchase. This will often mean your product features in your content higher up in the marketing funnel than it might for a B2B business, and you might need to use stronger calls-to-action (CTAs). For B2C companies, channels like Instagram and Pinterest can often be more valuable than business-focused platforms like LinkedIn.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default DigitalMarketing;
