import React from 'react';
import './firstpage.css';
import Img1 from '../img/Makenow logo.png';
import { Link, useLocation } from 'react-router-dom';

export const Footer = () => {
  const location = useLocation();

  // Check if the current location is the home page
  const isHomePage = location.pathname === '/';

  return (
    <div>
      {isHomePage && (
        <div className="pg-footer">
          <div className="footer">
            <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
              <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
            </svg>
            <div className="footer-content">
              <div className="footer-container">
                <div className="footer-logo">
                  <a className="footer-logo-link" href="/logo.png">
                    <img src={Img1} alt='img' className="footer-logo-img" />
                  </a>
                </div>
                <div className="footer-cards-container">
                  <div className="footer-card">
                    <img src="https://cdn-icons-png.flaticon.com/512/14/14558.png" alt="Chat Icon" className="footer-card-image"/>
                    <div className="footer-card-header">Chat with Us</div>
                    <div className="footer-card-content">Need a quick response? We're here to chat!<br/>
                    <strong>Monday-Friday</strong> 09:30 AM - 05:30 PM<br/>
                    <strong>Saturday-Sunday</strong> 09:00 AM - 05:00 PM</div>
                    <a href="#" className="footer-card-button">Chat Now</a>
                  </div>
                  <div className="footer-card">
                    <img src="https://www.freeiconspng.com/thumbs/phone-icon/clipart--phone-icon--clipart-best--clipart-best-28.png" alt="Contact Icon" className="footer-card-image"/>
                    <div className="footer-card-header">Contact Us</div>
                    <div className="footer-card-content">We're big fans of email. Reach out with any question or concerns and we will respond within 24 hours.<br/>
                            Stay connected with us!</div>
                    <a href="#" className="footer-card-button">Contact Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Footer bottom section */}
      <div class="footer-bottom">
        <div class="footer-bottom-social-icons">
          <div class="footer-bottom-social-icon">
            <a href="#" target="_blank">
              <img src="https://png.pngtree.com/png-clipart/20230401/original/pngtree-three-dimensional-instagram-icon-png-image_9015419.png" alt="Instagram Icon"/>
            </a>
          </div>
          <div class="footer-bottom-social-icon">
            <a href="#" target="_blank">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Facebook_Logo_2023.png/1200px-Facebook_Logo_2023.png" alt="Facebook Icon"/>
            </a>
          </div>
          <div class="footer-bottom-social-icon">
            <a href="#" target="_blank">
              <img src="https://static.vecteezy.com/system/resources/previews/016/716/465/original/gmail-icon-free-png.png" alt="Gmail Icon"/>
            </a>
          </div>
          <div class="footer-bottom-social-icon">
            <a href="#" target="_blank">
              <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png" alt="LinkedIn Icon"/>
            </a>
          </div>
        </div>

        <div class="footer-bottom-links">
          <Link to="/fresherscardpages" class="footer-bottom-link">Courses</Link>
          <Link to="/refund-policy" class="footer-bottom-link">Refund Policy</Link>
          <Link to="/reviews" class="footer-bottom-link">Reviews</Link>
          <Link to="/faq" class="footer-bottom-link">FAQ</Link>
          <Link to="/aboutus" class="footer-bottom-link">About</Link>
        </div>
      </div>
    </div>
  );
}
