import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const MySQL = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is MySQL?</h2>
        <p>
          MySQL is a relational database management system based on the Structured Query Language, which is the popular language for accessing and managing the records in the database. MySQL is open-source and free software under the GNU license. It is supported by Oracle Company.
        </p>

        <h2>How MySQL Works?</h2>
        <p>
          MySQL follows the working of Client-Server Architecture. This model is designed for the end-users called clients to access the resources from a central computer known as a server using network services. Here, the clients make requests through a graphical user interface (GUI), and the server will give the desired output as soon as the instructions are matched. The process of MySQL environment is the same as the client-server model.
        </p>

        <h2>Why Choose MySQL?</h2>
        <p>
          If you're looking for a free or low-cost database management system, several are available from which to choose: MySQL, PostgreSQL, SQLite, one of the free-but-unsupported engines from commercial vendors, and so forth. When you compare MySQL with other database systems, think about what's most important to you. Performance, support, features (such as SQL conformance or extensions), licensing conditions and restrictions, and price all are factors to take into account. Given these considerations, MySQL has many attractive features to offer:
          <ul>
            <li>Speed: MySQL is fast. Its developers contend that MySQL is about the fastest database system you can get.</li>
            <li>Ease of use: MySQL is a high-performance but relatively simple database system and is much less complex to set up and administer than larger systems.</li>
            <li>Query language support: MySQL understands SQL (Structured Query Language), the standard language of choice for all modern database systems.</li>
            <li>Capability: The MySQL server is multi-threaded, so many clients can connect to it at the same time. Each client can use multiple databases simultaneously. You can access MySQL interactively using several interfaces that let you enter queries and view the results.</li>
            <li>Connectivity and security: MySQL is fully networked, and databases can be accessed from anywhere on the Internet, so you can share your data with anyone, anywhere. But MySQL has access control so that one person who shouldn't see another's data cannot. To provide additional security, MySQL supports encrypted connections using the Secure Sockets Layer (SSL) protocol.</li>
            <li>Portability: MySQL runs on many varieties of Unix, as well as on other non-Unix systems, such as Windows, NetWare, and OS/2. MySQL runs on hardware from small personal computers (even palmtop devices) to high-end servers.</li>
            <li>Small size: MySQL has a modest distribution size, especially compared to the huge disk space footprint of certain commercial database systems.</li>
            <li>Availability and cost: MySQL is an Open Source project with dual licensing. First, it is available under the terms of the GNU General Public License (GPL). This means that MySQL is available without cost for most in-house uses. Second, for organizations that prefer or require formal arrangements or that do not want to be bound by the conditions of the GPL, commercial licenses are available.</li>
            <li>Open distribution and source code: MySQL is easy to obtain; just use your Web browser. If you don't understand how something works, are curious about an algorithm, or want to perform a security audit, you can get the source code and examine it. If you think you've found a bug, report it; the developers want to know.</li>
          </ul>
        </p>

        <h2>Why is MySQL so Popular?</h2>
        <p>
          MySQL is indeed not the only (R)DBMS on the market, but it is one of the most popular ones and only second to Oracle Database when scored using critical parameters like the number of mentions in search results, professional profiles on LinkedIn, and frequency of technical discussions on internet forums. The fact that many major tech giants rely on it further solidifies the well-deserved position. Why so? Here are the reasons:
          <ul>
            <li>Flexible and easy to use: You can modify the source code to meet your own expectations, and don’t need to pay anything for this level of freedom, including the options for upgrading to the advanced commercial version. The installation process is relatively simple, and shouldn’t take longer than 30 minutes.</li>
            <li>High Performance: A wide array of cluster servers backs MySQL. Whether you are storing massive amounts of big e-Commerce data or doing heavy business intelligence activities, MySQL can assist you smoothly with optimum speed.</li>
            <li>An industry standard: Industries have been using MySQL for years, which means that there are abundant resources for skilled developers. MySQL users can expect rapid development of the software and freelance experts willing to work for a smaller wage if they ever need them.</li>
            <li>Secure: Your data should be your primary concern when choosing the right RDBMS software. With its Access Privilege System and User Account Management, MySQL sets the security bar high. Host-based verification and password encryption are both available.</li>
          </ul>
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default MySQL;
