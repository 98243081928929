import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './nav.css'; // Import your CSS file for styling

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
      {/* Display only one link and dropdown arrow in mobile view */}
      <div className="mobile-nav">
        <div className="navbar-link" onClick={toggleMenu}>
          <span>Menu</span>
          <i className={`fas fa-chevron-down ${isOpen ? 'open' : ''}`}></i>
        </div>
        {/* Dropdown menu */}
        <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
        <Link to="/" className="navbar-link">Home</Link>
        <Link to="/aboutus" className="navbar-link">About-Us</Link>
        <Link to="/fresherscardpages" className="navbar-link">Courses</Link>
        <Link to="/Referandearn" className="navbar-link">Refer & Earn</Link>
        <Link to="/blog" className="navbar-link">Blog</Link>
        <Link to="/faq" className="navbar-link">Faq</Link>
          {/* Add more links as needed */}
        </div>
      </div>

      {/* Regular navbar for larger screens */}
      <div className="desktop-nav">
        <Link to="/" className="navbar-link">Home</Link>
        <Link to="/aboutus" className="navbar-link">About-Us</Link>
        <Link to="/fresherscardpages" className="navbar-link">Courses</Link>
        <Link to="/Referandearn" className="navbar-link">Refer & Earn</Link>
        <Link to="/blog" className="navbar-link">Blog</Link>
        <Link to="/faq" className="navbar-link">Faq</Link>
        {/* Add more links as needed */}
      </div>
    </nav>
  );
}

export default Navbar;
