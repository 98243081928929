import React, { useState, useEffect, useRef } from 'react';
import './home.css';
import Img from '../img/img3 (1).png';
import Img1 from '../img/Makenow logo.png';
import HorizontalScroll from 'react-scroll-horizontal';
import { Link } from 'react-router-dom';
import { Homee } from './homee';
 
export const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showDreamCloud, setShowDreamCloud] = useState(false);
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const [showRobotCenter, setShowRobotCenter] = useState(false);
  const [moveToCenter, setMoveToCenter] = useState(false);
  const [showLeftMessage, setShowLeftMessage] = useState(false);
  const [showRightMessage, setShowRightMessage] = useState(false);
  const [showAskExperienceMessage, setShowAskExperienceMessage] = useState(false);
 
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 1000);
 
    const dreamCloudTimeout = setTimeout(() => {
      setShowDreamCloud(true);
    }, 2000);
 
    const secondPhaseTimeout = setTimeout(() => {
      setShowSecondMessage(true);
      setTimeout(() => {
        setShowDreamCloud(false);
        setShowRobotCenter(true);
      }, 3000);
    }, 4000);
 
    const showAskExperienceMessageTimeout = setTimeout(() => {
      setShowAskExperienceMessage(true);
    }, 8500);
 
    return () => {
      clearTimeout(timeout);
      clearTimeout(dreamCloudTimeout);
      clearTimeout(secondPhaseTimeout);
      clearTimeout(showAskExperienceMessageTimeout);
    };
  }, []);
 
  const containerRef = useRef();
 
  useEffect(() => {
    const container = containerRef.current;
 
    const handleWheel = (event) => {
      container.scrollLeft += event.deltaX + event.deltaY;
      event.preventDefault();
    };
 
    container.addEventListener('wheel', handleWheel);
 
    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, []);
 
  useEffect(() => {
    if (showRobotCenter) {
      const moveToCenterTimeout = setTimeout(() => {
        setMoveToCenter(true);
      }, 1000);
 
      const leftMessageTimeout = setTimeout(() => {
        setShowLeftMessage(true);
      }, 1000);
 
      const rightMessageTimeout = setTimeout(() => {
        setShowRightMessage(true);
      }, 2000);
 
      return () => {
        clearTimeout(moveToCenterTimeout);
        clearTimeout(leftMessageTimeout);
        clearTimeout(rightMessageTimeout);
      };
    }
  }, [showRobotCenter]);
 
  return (
    <div className="background-video">
      <Homee/>
     <div className="boxxx" ref={containerRef}>
          <div className="page">
          <div className='pagee2'>
          <img  src={Img1} alt='img' className='imagee'/>
            <div className='main-heading-video welcome-animation motion-effect '>
              <div className='home-video' id='box1'>
              </div>
            </div>
            <div className={`popup-container ${showPopup ? 'show' : ''}`}>
              {showDreamCloud && (
                <>
                  <div className={`dream-cloud ${showSecondMessage ? 'move-right' : ''}`}>
                    <div className="cloud-message">
                      {showSecondMessage ? (
                        <p className="message-text">Let's Start <br/> Our Journey!</p>
                      ) : (
                        <p className="message-text">Welcome to <br/>MakeNow</p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {showAskExperienceMessage && (
                <div className="experience-message">
                  <div className='frsherorexpbox '>
                   <p className="message-textt">Are you <br/>
                   <Link to='/fresherscardpages'><button>Fresher</button></Link><br/> or <br/> <Link to='/experience'><button>Experienced</button></Link>  ?</p>
                  </div>
                </div>
              )}
              <img
                src={Img}
                alt="Robot Image"
                className={`
                  robot-image
                  ${showSecondMessage ? 'move-right' : ''}
                  ${showRobotCenter ? 'center' : ''}
                  ${moveToCenter ? 'move-to-center' : ''}
                `}
                onAnimationEnd={() => setMoveToCenter(false)}
              />
            </div>
            </div>
          </div>
     
      </div>
    </div>
  );
};