import React, { useEffect,useState } from 'react';
import './home.css'
import Imgg from  "../img/cash_credits_in_wallet_w_scene.png";
import Imgg1 from  "../img/link_blue.png";
import Imgg2 from  "../img/invite_orange.png";
import Imgg3 from  "../img/credits_prizes.png";
import Imgg4 from  "../img/credits_icon.png";
import Img11 from '../img/logo.png';
import Imgg5 from  "../img/credits_icon - Copy.png";
import Navbar from './nav';
import { Footer } from './footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
import Img0 from '../img/Screenshot 2024-02-19 122521.png';

export const Referandearn = () => {
  const [showCard, setShowCard] = useState(true);
  const handleEnrollClick = () => {
    window.location.href = 'https://www.makenow.co.in/';
  };
  return (
    <div>
    <img  src={Img11} alt='img' className='imagee'/>
        <Navbar/>
        
        <div className="whitebg">
      <div className="hero_section_wrapper">
        <br/>
        <br/>
        <br/>
        <br/>
       <div class="row" style={{display: "flex", justifyContent:"center"}}><h1>Refer & Earn</h1></div>
        <div style={{ position: 'relative', background: '#f8f8f8' }} className="hero_section">
        <div className="flex_basis_2 flex_1 flex_box_center_center col-md-6 d-md-none">
              <div style={{ position: 'relative' }} className="image center_align">
                <img
                  ng-src={Imgg}
                  className="mh mw inline_top"
                  loading="lazy"
                  src={Imgg}
                  alt=''
                />
              </div>
            </div>
        
          <div style={{ padding: '10px 10px' }} className="main_page_section_width margin_auto flex_box_center displayblockat700 d-flex flex-row justify-content-between row gx-5">
           
            <div className="flex_basis_2 flex_1 flex_box_center_center col-md-6 d-none d-md-block">
              <div style={{ position: 'relative' }} className="image center_align">
                <img
                  ng-src={Imgg}
                  className="mh mw inline_top"
                  loading="lazy"
                  src={Imgg}
                alt=''/>
              </div>
            </div>

            <div className="flex_basis_2 flex_1 content_wrapper  col-md-6">
              <div className="content full_h flex_box">
                <div className="c">
                  <div style={{ lineHeight: 1.3 }} className="title"><span>Now, it pays to spread your love for Cutshort!</span></div>
                  <div className="description mt10">
                    <div style={{ maxWidth: '400px' }} className="t">
                      <div className="t">You have told your friends to use Cutshort to find jobs or hire talent. Now, let us acknowledge your contribution. Helping your friends, helping us and helping yourself with some earnings - what could be possible be sweeter?</div>
                    </div>
                  <div ng-hide="hasParticipated()" className="btns_wrapper mt20">
                    <div style={{ marginLeft: '0' }} ng-click="enterContest(); trackAnyUnauth(&quot;Clicked to enter contest on tshirt contest page&quot;)" className="buttonnnnnn everyOtherGreenButton inline_middle m10 large"><span>Start inviting friends</span></div>
                    {/* <div ng-hide="current_user" ng-click="showLoginPopup({login_type: &quot;signin&quot;}); trackAnyUnauth(&quot;Clicked already entered on emplyer referral page&quot;)" className="btn everyOtherLink inline_middle">Already entered? Check your progress.</div> */}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page_sections_wrapper">
        <div className="page_sections">
          <div className="page_section_wrapper">
            <div className="page_section">
              <div className="content_wrapper p20 main_page_section_width">
                <div className="p20"></div>
                <div className="content">
                  <div className="sec_title center_align">
                    <div className="tttt">How it works</div>
                  </div>
                  <div className="p20"></div>
                  <style>
                    {`.how_it_works_content .ico{width:50px;height:50px;margin:0 auto}`}
                  </style>
                  <div className="sec_content how_it_works_content">
                    <div className="flex_box center_align displayblockat550 row">
                      <div className="flex_1 flex_basis_3 col-lg-4 col-md-12">
                        <div className="c">
                          <div className="ico">
                            <img
                              ng-src={Imgg1}
                              className="mw inline_top"
                              loading="lazy"
                              src={Imgg1}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className="p10"></div>
                        <div style={{ fontSize: '28px' }} className="mt5">
                          <div className="t">Get your invite link</div>
                        </div>
                        <div className="p5"></div>
                        <div className="desc">
                          <div className="ddddd">Generate your unique invite link to start inviting friends.</div>
                        </div>
                      </div>
                      <div className="flex_1 flex_basis_3 col-lg-4 col-md-6">
                        <div className="c">
                          <div className="ico">
                            <img
                              ng-src={Imgg2}
                              className="mw inline_top"
                              loading="lazy"
                              src={Imgg2}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className="p10"></div>
                        <div style={{ fontSize: '28px' }} className="mt5">
                          <div className="t">Invite your friends</div>
                        </div>
                        <div className="p5"></div>
                        <div className="desc">
                          <div className="ddddd">Share your invite link with friends. And make sure they complete registration on Cutshort.</div>
                        </div>
                      </div>
                      <div className="flex_1 flex_basis_3 col-lg-4 col-md-6">
                        <div className="c">
                          <div className="ico">
                            <img
                              ng-src={Imgg3}
                              className="mw inline_top"
                              loading="lazy"
                              src={Imgg3}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className="p10"></div>
                        <div style={{ fontSize: '28px' }} className="mt5">
                          <div className="t">Get credits + prizes</div>
                        </div>
                        <div className="p5"></div>
                        <div className="desc">
                          <div className="ddddd">Get cashback in your bank account and credits when a friend starts using Cutshort and/or makes a payment. Learn more below.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p10"></div>
              </div>
            </div>
          </div>
          <div className="page_section_wrapper">
            <div className="page_section">
              <div className="content_wrapper main_page_section_width">
                <div className="content">
                  <div className="sec_title center_align">
                    <div className="tttt">Rewards</div>
                  </div>
                  <div className="sec_content how_it_works_content">
                    <div className="description flex_box displayblockat700  d-flex flex-row justify-content-between row">
                      <div style={{ background: '#f5f5f5' }} className="flex_basis_4 flex_1 p15 radius5  m-4 col-lg-3 col-md-6">
                        <div style={{ fontSize: '28px' }} className="inline_top">
                          <div className="inline_middle">
                            <global-credit-coin size="large" display-only="true" coins="5">
                              <div className="credit_coin_indicator_wrapper">
                                <div style={{ fontSize: '28px' }} className="inline_flex_box_center">
                                  <div ng-hide="displayOnly" className="flex_no_shrink ng-hide"></div>
                                  <div className="flex_no_shrink">
                                    <span ng-hide="label">INR 50</span>
                                  </div>
                                  <div style={{ width: '25px', height: '25px' }} className="flex_no_shrink ml5">
                                    <img
                                      ng-src={Imgg4}
                                      className="mw inline_top"
                                      loading="lazy"
                                      src={Imgg4}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div>
                            </global-credit-coin>
                          </div>
                          <span className="ml4">in credits</span>
                        </div>
                        <div className="mt5">for every friend who signs up via your link.</div>
                      </div>
                      <div style={{ background: '#f5f5f5' }} className="flex_basis_4 flex_1 p15 radius5  m-4 col-lg-3 col-md-6">
                        <div style={{ fontSize: '28px' }} className="inline_top">
                          <div className="inline_middle">
                            <global-credit-coin size="large" display-only="true" coins="5">
                              <div className="credit_coin_indicator_wrapper">
                                <div style={{ fontSize: '28px' }} className="inline_flex_box_center">
                                  <div className="flex_no_shrink">
                                    <span ng-hide="label">INR 50</span>
                                  </div>
                                  <div style={{ width: '25px', height: '25px' }} className="flex_no_shrink ml5">
                                    <img
                                      ng-src={Imgg4}
                                      className="mw inline_top"
                                      loading="lazy"
                                      src={Imgg4}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div>
                            </global-credit-coin>
                          </div>
                          <span className="ml4">in credits</span>
                        </div>
                        <div className="mt5">when a friend starts looking for job.</div>
                      </div>
                      <div style={{ background: '#f5f5f5' }} className="flex_basis_4 flex_1 p15 radius5  m-4 col-lg-3 col-md-6">
                        <div style={{ fontSize: '28px' }} className="inline_top">
                          <div className="inline_middle">
                            <global-credit-coin size="large" display-only="true" coins="25">
                              <div className="credit_coin_indicator_wrapper">
                                <div style={{ fontSize: '28px' }} className="inline_flex_box_center">
                                  <div className="flex_no_shrink">
                                    <span ng-hide="label">INR 250</span>
                                  </div>
                                  <div style={{ width: '25px', height: '25px' }} className="flex_no_shrink ml5">
                                    <img
                                      ng-src={Imgg4}
                                      className="mw inline_top"
                                      loading="lazy"
                                      src={Imgg4}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div>
                            </global-credit-coin>
                          </div>
                          <span className="ml4">in credits</span>
                        </div>
                        <div className="mt5">when a friend adds a job to hire and becomes an employer.</div>
                      </div>      
                      <div style={{ background: '#f5f5f5' }} className="flex_basis_4 flex_1 p15 radius5  m-4 col-lg-3 col-md-6">
                        <div style={{ fontSize: '28px' }} className="inline_top">
                          <div className="inline_middle">
                            <global-credit-coin type="cash" size="large" display-only="true" label="5%">
                              <div className="credit_coin_indicator_wrapper">
                                <div style={{ fontSize: '28px' }} className="inline_flex_box_center">
                                  <div className="flex_no_shrink">
                                    <span ng-hide="label" className="ng-hide">INR </span>
                                    <span ng-show="label">5%</span>
                                  </div>
                                  <div style={{ width: '25px', height: '25px' }} className="flex_no_shrink ml5">
                                    <img
                                      ng-src={Imgg5}
                                      className="mw inline_top"
                                      loading="lazy"
                                      src={Imgg5}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div>
                            </global-credit-coin>
                          </div>
                          <span className="ml4">in cash</span>
                        </div>
                        <div className="mt5">of the subscription, when your employer friend makes a payment.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p10"></div>
                {/* <div className="center_alignn">
                  <a href="/faq" target="_blank" ng-click="trackEventHelper()" className="everyOtherSubtleButton inline_top">Learn about these rewards</a>
                </div> */}
                <div className="p20"></div>
                <div className="p10"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img0} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
    <Footer/>
  </div>
  )
}
