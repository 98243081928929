import React, { useEffect, useRef } from 'react';
import './timeline.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Contactus } from './contactus';
import { Homee } from './homee';
import { Footer } from './footer';


export const WebDevelopment = () => {
  const canvasRef = useRef(null);


  useEffect(() => {
     window.scrollTo(0, 0);
  
  });

  useEffect(() => {
    
    AOS.init();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let paused = true;
    let rafid = 0;

    const mouse = { x: 0, y: 0 };

    const options = {
      speed: 0.05, 
      density: 8,
      radius: 600,
    };

    const targets = [
      [29, 32, 48, 68],
      [29, 33, 38]
    ];

    const dotColors = [
      ['#13669b', 'rgba(19, 102, 155, 0.3)', 'rgba(19, 102, 155, 0.08)'],
      ['#7dd317', 'rgba(113, 222, 15, 0.3)', 'rgba(91, 164, 22, 0.12)'],
    ];

    const lines = [
      new Line(0, canvas.offsetHeight - 100, '#4789a3', options, mouse),
      new Line(0, canvas.offsetHeight - 100, '#a0d59c', options, mouse)
    ];

    function Line(y, height, color, options, mouse) {
      this.color = color;
      this.options = options;
      this.mouse = mouse;
      this.height = height;
      this.dots = [];
      this.y = y;
      this.points = [];

      this.reset = function (x) {
        this.points = [];
        for (let y = this.y; y < this.height; y += this.options.density)
          this.points.push(new Point(x, y, this.color));
      }

      this.update = function () {
        for (let i = 0; i < this.points.length; i++)
          this.points[i].update(this.mouse, this.options);
      }

      function Point(x, y) {
        this.y = y;
        this.x = x;
        this.base = { x: x, y: y };

        this.update = function (mouse, options) {
          const dx = this.x - mouse.x;
          const dy = this.y - mouse.y;
          const alpha = Math.atan2(dx, dy);
          const d = options.radius / Math.sqrt(dx * dx + dy * dy);

          this.y += Math.cos(alpha) * d + (this.base.y - this.y) * options.speed;
          this.x += Math.sin(alpha) * d + (this.base.x - this.x) * options.speed;
        }
      }
    }

    function drawCircle(p, r, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(p.x, p.y, r, 0, 2 * Math.PI, true);
      ctx.closePath();
      ctx.fill();
    }

    function drawLine(p1, p2) {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();
      ctx.closePath();
    }

    function redraw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      
        for (let i = 0; i < 2; i++) {
          const points = lines[i].points;
      
          // Check if points array is defined and has enough elements
          if (points && points.length >= 15) {
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.strokeStyle = lines[i].color;
      
            // Check if the points array has enough elements before accessing specific indices
            if (points[15]) {
              ctx.moveTo(points[15].x, points[15].y);
      
              for (let j = 15; j < points.length - 2; j++) {
                const point = points[j];
      
                // Check if the point object is defined before accessing its properties
                if (point) {
                  const xc = (points[j + 1].x + point.x) / 2;
                  const yc = (points[j + 1].y + point.y) / 2;
      
                  ctx.quadraticCurveTo(point.x, point.y, xc, yc);
                }
              }
              ctx.stroke();
              ctx.closePath();
      
              ctx.lineWidth = 1.2;
              ctx.strokeStyle = dotColors[i][2];
      
              // Check if lines[i].dots array is defined before iterating over it
              if (lines[i].dots) {
                for (let j = 0; j < lines[i].dots.length; j++) {
                  const dot = lines[i].dots[j];
      
                  // Check if the dot array and the corresponding targets array are defined
                  if (dot && targets[i]) {
                    const id = targets[i][j];
      
                    // Check if the points array has enough elements before accessing specific indices
                    if (points[id] && points[id + 1]) {
                      const dot2 = [
                        (lines[i].points[id].x + lines[i].points[id + 1].x) / 2,
                        (lines[i].points[id].y + lines[i].points[id + 1].y) / 2,
                      ];
      
                      const p1 = { x: dot[0], y: dot[1] };
                      const p2 = { x: dot2[0], y: dot2[1] };
      
                      drawLine(p1, p2);
                      drawCircle(p1, 3, dotColors[i][0]);
      
                      drawCircle(p2, 11, dotColors[i][1]);
                      drawCircle(p2, 5.5, dotColors[i][0]);
                    }
                  }
                }
              }
            }
          }
        }
      }
      

    function animate() {
      rafid = requestAnimationFrame(animate);

      lines[0].update();
      lines[1].update();

      redraw();
    }

    function toggle(run) {
      if (run === undefined)
        toggle(!paused);
      else if (!!run && paused) {
        paused = false;
        animate();
      } else if (!!!run) {
        paused = true;
        cancelAnimationFrame(rafid);
      }
      return true;
    }

    function init() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);

      return true;
    }

    function InitDots() {
        const tl = document.querySelector('.timeline');
        const top = tl.querySelector('h2').offsetHeight;
      
        lines[0].dots = [];
        let y = top;
        tl.querySelector('article:first-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[0].dots.push([figure.offsetWidth + 20, y + 20]);
          y += figure.offsetHeight;
        });
      
        lines[1].dots = [];
        y = top;
        tl.querySelector('article:last-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[1].dots.push([canvas.width - figure.offsetWidth - 20, y + 20]);
          y += figure.offsetHeight;
        });
      }

    function OnResize() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);
    }

    init();
    animate();

    window.addEventListener('mousemove', (e) => {
      if (e.offsetX) {
        mouse.x = e.offsetX;
        mouse.y = e.offsetY;
      } else if (e.layerX) {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
      } else {
        mouse.x = e.pageX - canvas.offsetLeft;
        mouse.y = e.pageY - canvas.offsetTop;
      }
    });

    window.addEventListener('resize', OnResize);

    return () => {
      // Cleanup event listeners or perform any cleanup if needed
      window.removeEventListener('mousemove', () => {});
      window.removeEventListener('resize', OnResize);
      cancelAnimationFrame(rafid);
      AOS.refresh();
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render



  return (
    <section id="timeline">
      <Homee/>
      <br/>
      <br/>
    <Link to="/fresherscardpages" >
      <div >
      <i class="fa-solid fa-arrow-left backbutton"></i><snap className="backtext">Back</snap>
      </div>
      </Link>

      <Contactus/>

      <div className="timeline" data-aos="fade-up">
        <canvas id="cvs3" ref={canvasRef}></canvas>
       <div data-aos="fade-up">
        <h2>Web Development</h2>
       <article>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 1-2</figcaption>
            <h6>HTML & CSS BASICS</h6>
            <p>
            • HTML tags, structure, and basic elements.<br/>
            • Create a simple webpage.<br/>
            • Understanding CSS syntax, selectors, and properties.<br/>
            • Style your HTML page with different layouts and designs.<br/>
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 5-6</figcaption>
            <h6>ADVANCED JAVASCRIPT</h6>
            <p>
            • Understand how to interact with the Document Object    Model.<br/>
            • Practice utilising JavaScript to manipulate HTML components.<br/>
            • About events and asynchronous programming.<br/>
            • Introduce AJAX and fetch for data retrieval.<br/>
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 9-10</figcaption>
            <h6>NODE.JS BASICS</h6>
            <p>
            • Node.js and its asynchronous, event-driven architecture.<br/>
            • Set up a basic Node.js server.<br/>
            • Understanding the Express.js framework for building web applications.<br/>
            • Build a simple web application using Node.js and Express.js.<br/>
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 13-14 </figcaption>
            <h6>PROJECT</h6>
            <p>
            • Work on a comprehensive project integrating all learned technologies.<br/>
            • Learn about deployment strategies and deploy the project.
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption> 4-6 MONTHS</figcaption>
            <h6>PLACEMENT</h6>
            <p>
            Experience a career leap in just 4 to 6 months as our program seamlessly guides you from learning to successful placements, ensuring a rapid entry into your dream profession.
            </p>
          </figure>
          
        
          
        </article>
        <article>
        
          <figure data-aos="fade-left">
            <figcaption>WEEKS 3-4</figcaption>
            <h6>JAVASCRIPT BASICS</h6>
            <p>
            • Variables, data types, and basic operators.<br/>
            • Understanding control structures (if statements, loops).<br/>
            • Dive into functions and objects in JavaScript.<br/>
            • Start building simple programs to practice.<br/>
            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 7-8</figcaption>
            <h6>REACT.JS BASICS</h6>
            <p>
            • Understanding the basics of React components and JSX.<br/>
            • Build simple React components.<br/>
            • About component state and properties.<br/>
            • Build more complex React applications.<br/>
            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 11-12</figcaption>
            <h6>DATABASES AND SQL</h6>
            <p>
            • Databases, relational databases, and non-relational databases.<br/>
            • Understanding the basics of SQL.<br/>
            • Explore database design principles.<br/>
            • Practice writing SQL queries for data retrieval and manipulation.
            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>15 DAYS</figcaption>
            <h6>MOCK INTERVIEWS AND RESUME PREPARATION</h6>
            <p>
            Mock interviews and resume preparation sessions provide students with hands-on experience, refining their interview skills and crafting compelling resumes to enhance their professional readiness.<br/>
            </p>
          </figure>
         
         
        </article>
       </div>
        <br style={{ clear: 'both' }} />
      </div>

    <Footer/>
    </section>
  );
};


