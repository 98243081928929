import React, { useEffect, useRef } from 'react';
import './timeline.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Contactus } from './contactus';
import { Homee } from './homee';
import { Footer } from './footer';



export const Etltesting = () => {
  const canvasRef = useRef(null);




  useEffect(() => {
    AOS.init();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let paused = true;
    let rafid = 0;

    const mouse = { x: 0, y: 0 };

    const options = {
      speed: 0.05, // Adjusted for slower motion
      density: 8,
      radius: 600,
    };

    const targets = [
      [29, 32, 48, 68],
      [29, 33, 38]
    ];

    const dotColors = [
      ['#13669b', 'rgba(19, 102, 155, 0.3)', 'rgba(19, 102, 155, 0.08)'],
      ['#7dd317', 'rgba(113, 222, 15, 0.3)', 'rgba(91, 164, 22, 0.12)'],
    ];

    const lines = [
      new Line(0, canvas.offsetHeight - 100, '#4789a3', options, mouse),
      new Line(0, canvas.offsetHeight - 100, '#a0d59c', options, mouse)
    ];

    function Line(y, height, color, options, mouse) {
      this.color = color;
      this.options = options;
      this.mouse = mouse;
      this.height = height;
      this.dots = [];
      this.y = y;
      this.points = [];

      this.reset = function (x) {
        this.points = [];
        for (let y = this.y; y < this.height; y += this.options.density)
          this.points.push(new Point(x, y, this.color));
      }

      this.update = function () {
        for (let i = 0; i < this.points.length; i++)
          this.points[i].update(this.mouse, this.options);
      }

      function Point(x, y) {
        this.y = y;
        this.x = x;
        this.base = { x: x, y: y };

        this.update = function (mouse, options) {
          const dx = this.x - mouse.x;
          const dy = this.y - mouse.y;
          const alpha = Math.atan2(dx, dy);
          const d = options.radius / Math.sqrt(dx * dx + dy * dy);

          this.y += Math.cos(alpha) * d + (this.base.y - this.y) * options.speed;
          this.x += Math.sin(alpha) * d + (this.base.x - this.x) * options.speed;
        }
      }
    }

    function drawCircle(p, r, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(p.x, p.y, r, 0, 2 * Math.PI, true);
      ctx.closePath();
      ctx.fill();
    }

    function drawLine(p1, p2) {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();
      ctx.closePath();
    }

    function redraw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      
        for (let i = 0; i < 2; i++) {
          const points = lines[i].points;
      
          // Check if points array is defined and has enough elements
          if (points && points.length >= 15) {
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.strokeStyle = lines[i].color;
      
            // Check if the points array has enough elements before accessing specific indices
            if (points[15]) {
              ctx.moveTo(points[15].x, points[15].y);
      
              for (let j = 15; j < points.length - 2; j++) {
                const point = points[j];
      
                // Check if the point object is defined before accessing its properties
                if (point) {
                  const xc = (points[j + 1].x + point.x) / 2;
                  const yc = (points[j + 1].y + point.y) / 2;
      
                  ctx.quadraticCurveTo(point.x, point.y, xc, yc);
                }
              }
              ctx.stroke();
              ctx.closePath();
      
              ctx.lineWidth = 1.2;
              ctx.strokeStyle = dotColors[i][2];
      
              // Check if lines[i].dots array is defined before iterating over it
              if (lines[i].dots) {
                for (let j = 0; j < lines[i].dots.length; j++) {
                  const dot = lines[i].dots[j];
      
                  // Check if the dot array and the corresponding targets array are defined
                  if (dot && targets[i]) {
                    const id = targets[i][j];
      
                    // Check if the points array has enough elements before accessing specific indices
                    if (points[id] && points[id + 1]) {
                      const dot2 = [
                        (lines[i].points[id].x + lines[i].points[id + 1].x) / 2,
                        (lines[i].points[id].y + lines[i].points[id + 1].y) / 2,
                      ];
      
                      const p1 = { x: dot[0], y: dot[1] };
                      const p2 = { x: dot2[0], y: dot2[1] };
      
                      drawLine(p1, p2);
                      drawCircle(p1, 3, dotColors[i][0]);
      
                      drawCircle(p2, 11, dotColors[i][1]);
                      drawCircle(p2, 5.5, dotColors[i][0]);
                    }
                  }
                }
              }
            }
          }
        }
      }
      

    function animate() {
      rafid = requestAnimationFrame(animate);

      lines[0].update();
      lines[1].update();

      redraw();
    }

    function toggle(run) {
      if (run === undefined)
        toggle(!paused);
      else if (!!run && paused) {
        paused = false;
        animate();
      } else if (!!!run) {
        paused = true;
        cancelAnimationFrame(rafid);
      }
      return true;
    }

    function init() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);

      return true;
    }

    function InitDots() {
        const tl = document.querySelector('.timeline');
        const top = tl.querySelector('h2').offsetHeight;
      
        lines[0].dots = [];
        let y = top;
        tl.querySelector('article:first-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[0].dots.push([figure.offsetWidth + 20, y + 20]);
          y += figure.offsetHeight;
        });
      
        lines[1].dots = [];
        y = top;
        tl.querySelector('article:last-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[1].dots.push([canvas.width - figure.offsetWidth - 20, y + 20]);
          y += figure.offsetHeight;
        });
      }

    function OnResize() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);
    }



    init();
    animate();

    window.addEventListener('mousemove', (e) => {
      if (e.offsetX) {
        mouse.x = e.offsetX;
        mouse.y = e.offsetY;
      } else if (e.layerX) {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
      } else {
        mouse.x = e.pageX - canvas.offsetLeft;
        mouse.y = e.pageY - canvas.offsetTop;
      }
    });

    window.addEventListener('resize', OnResize);

    return () => {
      // Cleanup event listeners or perform any cleanup if needed
      window.removeEventListener('mousemove', () => {});
      window.removeEventListener('resize', OnResize);
      cancelAnimationFrame(rafid);
      AOS.refresh();
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <section id="timeline">
      <Homee/>
      <br/>
    <Link to="/experience" >
      <div>
      <i class="fa-solid fa-arrow-left backbutton"></i><snap className="backtext">Back</snap>
      </div>
      </Link>

      <Contactus/>

      <div className="timeline" data-aos="fade-up">
        <canvas id="cvs3" ref={canvasRef}></canvas>
       <div data-aos="fade-up">
        <h2>ETL Testing</h2>
       <article>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 1-2</figcaption>
            <h6>Introduction to ETL and Data Warehousing</h6>
            <p>
            •	Day 1-2: Overview of ETL processes and their significance in data management.<br/>
            •	Day 3-4: Introduction to Data Warehousing concepts.<br/>
            •	Day 5-7: Understanding the role of ETL testing in the data warehousing lifecycle<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 5-6</figcaption>
            <h6>ETL Tools and Environments</h6>
            <p>
            •	Day 1-3: Introduction to popular ETL tools like Informatica, Talend, or Apache NiFi.<br/>
            •	Day 4-6: Hands-on exercises with a chosen ETL tool.<br/>
            •	Day 7: Discussion on the advantages and disadvantages of different ETL tools.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 9-10</figcaption>
            <h6>Test Case Design for ETL Testing</h6>
            <p>
        	•	Day 1-3: Principles of designing effective ETL test cases.<br/>
            •	Day 4-5: Hands-on practice in creating test cases for ETL processes.<br/>
            •	Day 6-7: Review and feedback on test case design.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 13-14 </figcaption>
            <h6>Performance Testing in ETL</h6>
            <p>
            •	Day 1-3: Basics of performance testing in ETL.<br/>
            •	Day 4-5: Performance testing tools and methodologies.<br/>
            •	Day 6-7: Hands-on performance testing exercises.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 17-18 </figcaption>
            <h6>Integration with QA Processes</h6>
            <p>
            •	Day 1-3: Integrating ETL testing into the overall QA process.<br/>
            •	Day 4-7: Best practices and collaboration with development and operations teams.<br/>
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption> 4-6 MONTHS</figcaption>
            <h6>PLACEMENT</h6>
            <p>
            Experience a career leap in just 4 to 6 months as our program seamlessly guides you from learning to successful placements, ensuring a rapid entry into your dream profession.<br/>
            </p>
          </figure>
        </article>
        <article>
        
          <figure data-aos="fade-left">
            <figcaption>WEEKS 3-4</figcaption>
            <h6>Basics of SQL and Relational Databases</h6>
            <p>
            •	Day 1-5: SQL fundamentals for querying and manipulating data.<br/>
            •	Day 6-7: Overview of relational databases and how they are used in ETL processes.<br/>

             </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 7-8</figcaption>
            <h6>ETL Testing Fundamentals</h6>
            <p>
            •	Day 1-3: Basics of ETL testing, including data profiling and validation.<br/>
            •	Day 4-5: Understanding the different types of data anomalies.<br/>
            •	Day 6-7: Practical exercises in ETL testing.<br/>


            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 11-12</figcaption>
            <h6>Automation in ETL Testing</h6>
            <p>
            •	Day 1-3: Introduction to automation tools for ETL testing.<br/>
            •	Day 4-7: Building and executing automated test scripts.<br/>

            </p>
          </figure>  
          <figure data-aos="fade-left">
            <figcaption>WEEKS 15-16</figcaption>
            <h6>Advanced ETL Testing Concepts</h6>
            <p>
            •	Day 1-5: Advanced topics such as incremental loading, change data capture, and error handling.<br/>
            •	Day 6-7: Case studies and real-world scenarios in ETL testing.<br/>
            </p>
          </figure> 
          <figure data-aos="fade-left">
            <figcaption>Project</figcaption>
            <h6>Project and Deployment</h6>
            <p>
            •	Final project where students apply all learned concepts<br/>
            •	Presentation of the project to the class and/or industry professionals<br/>
            •	Peer review and feedback<br/>

                        </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>15 DAYS</figcaption>
            <h6>MOCK INTERVIEWS AND RESUME PREPARATION</h6>
            <p>
            Mock interviews and resume preparation sessions provide students with hands-on experience, refining their interview skills and crafting compelling resumes to enhance their professional readiness.<br/>
            </p>
          </figure>
         
         
        </article>
       </div>
        <br style={{ clear: 'both' }} />
      </div>
      <Footer/>
    </section>
  );
};


