import React, { useEffect, useRef } from 'react';
import './timeline.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Contactus } from './contactus';
import { Homee } from './homee';
import { Footer } from './footer';


export const Mainframes = () => {
  const canvasRef = useRef(null);



  useEffect(() => {
    AOS.init();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let paused = true;
    let rafid = 0;

    const mouse = { x: 0, y: 0 };

    const options = {
      speed: 0.05, // Adjusted for slower motion
      density: 8,
      radius: 600,
    };

    const targets = [
      [29, 32, 48, 68],
      [29, 33, 38]
    ];

    const dotColors = [
      ['#13669b', 'rgba(19, 102, 155, 0.3)', 'rgba(19, 102, 155, 0.08)'],
      ['#7dd317', 'rgba(113, 222, 15, 0.3)', 'rgba(91, 164, 22, 0.12)'],
    ];

    const lines = [
      new Line(0, canvas.offsetHeight - 100, '#4789a3', options, mouse),
      new Line(0, canvas.offsetHeight - 100, '#a0d59c', options, mouse)
    ];

    function Line(y, height, color, options, mouse) {
      this.color = color;
      this.options = options;
      this.mouse = mouse;
      this.height = height;
      this.dots = [];
      this.y = y;
      this.points = [];

      this.reset = function (x) {
        this.points = [];
        for (let y = this.y; y < this.height; y += this.options.density)
          this.points.push(new Point(x, y, this.color));
      }

      this.update = function () {
        for (let i = 0; i < this.points.length; i++)
          this.points[i].update(this.mouse, this.options);
      }

      function Point(x, y) {
        this.y = y;
        this.x = x;
        this.base = { x: x, y: y };

        this.update = function (mouse, options) {
          const dx = this.x - mouse.x;
          const dy = this.y - mouse.y;
          const alpha = Math.atan2(dx, dy);
          const d = options.radius / Math.sqrt(dx * dx + dy * dy);

          this.y += Math.cos(alpha) * d + (this.base.y - this.y) * options.speed;
          this.x += Math.sin(alpha) * d + (this.base.x - this.x) * options.speed;
        }
      }
    }

    function drawCircle(p, r, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(p.x, p.y, r, 0, 2 * Math.PI, true);
      ctx.closePath();
      ctx.fill();
    }

    function drawLine(p1, p2) {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();
      ctx.closePath();
    }

    function redraw() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      
        for (let i = 0; i < 2; i++) {
          const points = lines[i].points;
      
          // Check if points array is defined and has enough elements
          if (points && points.length >= 15) {
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.strokeStyle = lines[i].color;
      
            // Check if the points array has enough elements before accessing specific indices
            if (points[15]) {
              ctx.moveTo(points[15].x, points[15].y);
      
              for (let j = 15; j < points.length - 2; j++) {
                const point = points[j];
      
                // Check if the point object is defined before accessing its properties
                if (point) {
                  const xc = (points[j + 1].x + point.x) / 2;
                  const yc = (points[j + 1].y + point.y) / 2;
      
                  ctx.quadraticCurveTo(point.x, point.y, xc, yc);
                }
              }
              ctx.stroke();
              ctx.closePath();
      
              ctx.lineWidth = 1.2;
              ctx.strokeStyle = dotColors[i][2];
      
              // Check if lines[i].dots array is defined before iterating over it
              if (lines[i].dots) {
                for (let j = 0; j < lines[i].dots.length; j++) {
                  const dot = lines[i].dots[j];
      
                  // Check if the dot array and the corresponding targets array are defined
                  if (dot && targets[i]) {
                    const id = targets[i][j];
      
                    // Check if the points array has enough elements before accessing specific indices
                    if (points[id] && points[id + 1]) {
                      const dot2 = [
                        (lines[i].points[id].x + lines[i].points[id + 1].x) / 2,
                        (lines[i].points[id].y + lines[i].points[id + 1].y) / 2,
                      ];
      
                      const p1 = { x: dot[0], y: dot[1] };
                      const p2 = { x: dot2[0], y: dot2[1] };
      
                      drawLine(p1, p2);
                      drawCircle(p1, 3, dotColors[i][0]);
      
                      drawCircle(p2, 11, dotColors[i][1]);
                      drawCircle(p2, 5.5, dotColors[i][0]);
                    }
                  }
                }
              }
            }
          }
        }
      }
      

    function animate() {
      rafid = requestAnimationFrame(animate);

      lines[0].update();
      lines[1].update();

      redraw();
    }

    function toggle(run) {
      if (run === undefined)
        toggle(!paused);
      else if (!!run && paused) {
        paused = false;
        animate();
      } else if (!!!run) {
        paused = true;
        cancelAnimationFrame(rafid);
      }
      return true;
    }

    function init() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);

      return true;
    }

    function InitDots() {
        const tl = document.querySelector('.timeline');
        const top = tl.querySelector('h2').offsetHeight;
      
        lines[0].dots = [];
        let y = top;
        tl.querySelector('article:first-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[0].dots.push([figure.offsetWidth + 20, y + 20]);
          y += figure.offsetHeight;
        });
      
        lines[1].dots = [];
        y = top;
        tl.querySelector('article:last-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[1].dots.push([canvas.width - figure.offsetWidth - 20, y + 20]);
          y += figure.offsetHeight;
        });
      }

    function OnResize() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);
    }



    init();
    animate();

    window.addEventListener('mousemove', (e) => {
      if (e.offsetX) {
        mouse.x = e.offsetX;
        mouse.y = e.offsetY;
      } else if (e.layerX) {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
      } else {
        mouse.x = e.pageX - canvas.offsetLeft;
        mouse.y = e.pageY - canvas.offsetTop;
      }
    });

    window.addEventListener('resize', OnResize);

    return () => {
      // Cleanup event listeners or perform any cleanup if needed
      window.removeEventListener('mousemove', () => {});
      window.removeEventListener('resize', OnResize);
      cancelAnimationFrame(rafid);
      AOS.refresh();
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <section id="timeline">
<Homee/>
<br/>
<Link to="/experience" >
      <div >
      <i class="fa-solid fa-arrow-left backbutton"></i><snap className="backtext">Back</snap>
      </div>
      </Link>

    <Contactus/>

      <div className="timeline" data-aos="fade-up">
        <canvas id="cvs3" ref={canvasRef}></canvas>
       <div data-aos="fade-up">
        <h2>Mainframes</h2>
       <article>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 1-2</figcaption>
            <h6>Introduction to Mainframes</h6>
            <p>
            •	Overview of mainframes: What they are, their history, and their significance in the computing world.<br/>
            •	Introduction to mainframe architecture: Hardware components, such as processors, memory, storage, and peripherals.<br/>
            •	Understanding the mainframe operating system: Commonly used operating systems like z/OS, z/VM, or z/VSE.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 5-8</figcaption>
            <h6>Programming Languages on Mainframes</h6>
            <p>
            •	Introduction to mainframe programming languages: COBOL, PL/I, Assembler, and others.<br/>
            •	Hands-on coding exercises to build familiarity with mainframe programming.<br/>
            •	Debugging and testing programs on the mainframe environment.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 13-16</figcaption>
            <h6>Mainframe Networking and Security</h6>
            <p>
        	•	Understanding mainframe networking: Networking protocols and communication on mainframes.<br/>
            •	Introduction to mainframe security: User authentication, access control, and encryption.<br/>
            •	Practical exercises to secure mainframe systems.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>WEEKS 21-24 </figcaption>
            <h6>Mainframe Modernization and Integration</h6>
            <p>
            •	Exploring mainframe modernization strategies.<br/>
            •	Integration of mainframes with modern technologies: APIs, web services, and cloud computing.<br/>
            •	Hands-on projects involving the integration of mainframe systems with contemporary technologies.<br/>

            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption> 4-6 MONTHS</figcaption>
            <h6>PLACEMENT</h6>
            <p>
            Experience a career leap in just 4 to 6 months as our program seamlessly guides you from learning to successful placements, ensuring a rapid entry into your dream profession.<br/>
            </p>
          </figure>
        </article>
        <article>
        
          <figure data-aos="fade-left">
            <figcaption>WEEKS 3-4</figcaption>
            <h6>Basic Mainframe Operations</h6>
            <p>
            •	Hands-on experience with basic mainframe operations.<br/>
            •	Introduction to the mainframe user interface: Using tools like ISPF (Interactive System Productivity Facility) for navigation and interaction.<br/>
            •	File systems and datasets: Understanding how data is stored and managed on mainframes.<br/>

             </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 9-12</figcaption>
            <h6>Database Management on Mainframes</h6>
            <p>
            •	Overview of mainframe databases: DB2, IMS, and other mainframe database management systems.<br/>
            •	Structured Query Language (SQL) for mainframes.<br/>
            •	Designing, creating, and managing databases on the mainframe<br/>

            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>WEEKS 17-20</figcaption>
            <h6>Batch Processing and Job Control Language (JCL)</h6>
            <p>
            •	Overview of batch processing on mainframes.<br/>
            •	Introduction to Job Control Language (JCL): Writing JCL scripts to execute batch jobs.<br/>
            •	Automation and scheduling of batch processes.<br/>

            </p>
          </figure>  
          <figure data-aos="fade-left">
            <figcaption>Project</figcaption>
            <h6>Project and Deployment</h6>
            <p>
            •	Final project where students apply all learned concepts<br/>
            •	Presentation of the project to the class and/or industry professionals<br/>
            •	Peer review and feedback<br/>

                        </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>15 DAYS</figcaption>
            <h6>MOCK INTERVIEWS AND RESUME PREPARATION</h6>
            <p>
            Mock interviews and resume preparation sessions provide students with hands-on experience, refining their interview skills and crafting compelling resumes to enhance their professional readiness.<br/>
            </p>
          </figure>
         
         
        </article>
       </div>
        <br style={{ clear: 'both' }} />
      </div>
      <Footer/>
    </section>
  );
};


