import React from 'react'
import './login.css'
import Imgg12 from '../img/image-1.png'
import Imgg123 from '../img/image-2.png'
export const Login = () => {
  return (
    <div>
         <div className="registration-wrapper">
        <div className="registration-inner">
          <img src={Imgg12} alt="" className="registration-img-1" />
          <form action="" className="registration-form">
            <h3 className="registration-heading">New Account?</h3>
            <div className="registration-input-holder">
              <span className="registration-icon-user"></span>
              <input type="text" className="registration-input" placeholder="Username" />
            </div>
            <div className="registration-input-holder">
              <span className="registration-icon-phone"></span>
              <input type="text" className="registration-input" placeholder="Phone Number" />
            </div>
            <div className="registration-input-holder">
              <span className="registration-icon-envelope"></span>
              <input type="text" className="registration-input" placeholder="Mail" />
            </div>
            <div className="registration-input-holder">
              <span className="registration-icon-lock"></span>
              <input type="password" className="registration-input" placeholder="Password" />
            </div>
            <div className="registration-input-holder">
              <span className="registration-icon-lock"></span>
              <input type="password" className="registration-input" placeholder="Confirm Password" />
            </div>
            <button className="registration-btn">
              <span>Register</span>
            </button>
          </form>
          <img src={Imgg123} alt="" className="registration-img-2" />
        </div>
      </div>
    </div>
  )
}
