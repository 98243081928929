import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const PowerBI = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Power BI?</h2>
        <p>
          Power BI is a collection of software services, apps, and connectors that work together to turn your unrelated sources of data into coherent, visually immersive, and interactive insights. Your data may be an Excel spreadsheet, or a collection of cloud-based and on-premises hybrid data warehouses.
        </p>

        <h2>What does Power BI do?</h2>
        <p>
          Microsoft Power BI is used to run reports and surface insights based on a company’s data. Power BI can connect to a wide range of data sets, and “tidies up” the info it’s fed so that it can be better digested and understood. The reports and visuals generated from this data can then be shared with other users.
          Power BI helps users see not only what’s happened in the past and what’s happening in the present, but also what might happen in the future. Power BI is infused with machine learning capabilities, meaning it can spot patterns in data and use those patterns to make informed predictions and run “what if” scenarios. These estimates allow users to generate forecasts, and prepare themselves to meet future demand and other key metrics.
        </p>

        <h2>Why do people use Power BI?</h2>
        <p>
          For businesses who want more reporting power and analytical muscle than that offered by Excel, Power BI is the next level in business intelligence. With Power BI, businesses can assemble, scrutinize, and visualize data from across the company, giving them greater insight into their operations and performance, and allowing them to make more informed decisions based on real data.
          Here are some of the key benefits of using Power BI:
          <ul>
            <li>Businesses can input huge quantities of data into Power BI that many other platforms would struggle to process</li>
            <li>Built-in machine learning features can analyze data and help users spot valuable trends and make educated predictions</li>
            <li>Information can be visualized using powerful templates to allow businesses to better make sense of their data</li>
            <li>Power BI is cloud-based, so users get cutting edge intelligence capabilities and powerful algorithms that are updated regularly</li>
            <li>Powerful personalization capabilities allow users to create dashboards so they can access the data they need quickly</li>
            <li>Alerts can be set up on KPIs to keep users up to date important metrics and measurements.</li>
            <li>Power BI has an intuitive interface that makes it far more user-friendly and easy to navigate then complex spreadsheets</li>
            <li>The platform integrates with other popular business management tools like SharePoint, Office 365, and Dynamics 365, as well as other non-Microsoft products like Spark, Hadoop, Google Analytics, SAP, Salesforce, and MailChimp</li>
            <li>With data security a massive talking point for modern businesses, Power BI ensures data is safe, offering granular controls on accessibility both internally and externally</li>
          </ul>
        </p>

        <h2>Who uses Power BI?</h2>
        <p>
          Though business intelligence products tend to be mainly the remit of business analysts and data scientists, thanks to its user-friendly nature, Power BI can be used by a range of people within a business.
          How you use it depends on what your role is; Power BI can generate custom dashboards depending on what data is relevant, and what information you need access to.
          It’s primarily used by business intelligence professionals, who use it to create data models and reports which are then shared throughout their organization. Not all businesses will have the scale or the need to support a full-time data whiz, however, so Power BI is often used as a self-service tool by various branches of the business to check up on progress and turn up insight into their team’s performance.
          Power BI works with whatever data you tell it to, so you can report on pretty much anything. It’s often most popular with departments like finance, marketing, sales, human resources, it, and operations.
          In terms of the product’s popularity, Microsoft doesn’t typically release user numbers, but in 2016 the number was around five million.
        </p>

        <h2>How does Power BI Work?</h2>
        <p>
          The process in which Power BI works is relatively easy to understand as there are no complex steps involved. Here are 4 steps on how Power BI works:
          <ol>
            <li><strong>Integration of Data:</strong> As the first step in the working of Power BI, data from various sources, servers, and dashboards are collected and compressed as the maximum limit to import is only 1GB [Free version]. The compressed data will be transferred to a standard format for storage at the “Staging Area."</li>
            <li><strong>Transformation of Data:</strong> Apart from the visual effects of the data, it has to be first cleaned and checked for any missing or redundant, or duplicate values or should be pre-processed. These are then loaded in and saved in data warehouses.</li>
            <li><strong>Report & Publishing:</strong> The user can prepare various reports like graphs, charts, and others, in reference to the data stored, in their Power BI Platforms and publish them in On-premise Power BI Server and also in Power BI Services.</li>
            <li><strong>Dashboard and Its Creation:</strong> Dashboards are created to withhold the individual elements in the data and these are usually performed after publishing the reports of the respective transformed data to Power BI Services.</li>
          </ol>
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default PowerBI;
