import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "./faq.css";
import Img13 from '../img/Makenow logo.png';

const Header = () => {
  return (
    <header className="header">
     <img  src={Img13} alt='img' className='imaga'/>
      <h1>FAQ</h1>
      <Link to="/" className="back-button"> {/* Add class for styling */}
      </Link>
    </header>
  );
};

export default Header;
