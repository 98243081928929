import React, { useEffect,useState } from 'react';
import "./faq.css";
import Header from "./header";
import {Footer} from './footer'; 
import Navbar  from './nav';
import Faqs from "./faqs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 
import Img2 from '../img/Screenshot 2024-02-19 122521.png';


export default function App() {
  const [showCard, setShowCard] = useState(true);
  const handleEnrollClick = () => {
    window.location.href = 'https://www.makenow.co.in/';
  };
  const [faqs, setFaqs] = useState([
    {
      question: "What is the Job Guaranteed Program offered by MakeNow Academy?",
      answer:
        "The Job Guaranteed Program at MakeNow Academy is a comprehensive initiative designed to ensure our students' success by guaranteeing job placement upon successful completion of the program. ",
      open: true
    },
    {
      question: "How does the Job Guaranteed Program work?",
      answer: "Upon completing the program requirements and meeting eligibility criteria, students are guaranteed job placement within a specified timeframe. ",
      open: false
    },
    {
      question:
        "What are the eligibility criteria for the Job Guaranteed Program?",
      answer: "Eligibility criteria may vary depending on the specific program. However, typical requirements include successful completion of all program requirements, demonstrated proficiency in key skills, and active participation in career development activities. ",
      open: false
    },
    {
      question: "Is the job placement guarantee applicable to all students?",
      answer: "The job placement guarantee is subject to meeting eligibility criteria and program requirements. Our career services team works closely with each student to ensure they are well-prepared for the job market. ",
      open: false
    },
    {
      question:
        "What types of jobs are included in the placement guarantee?",
      answer: "We partner with a wide range of companies across various industries to offer job opportunities in fields such as software development, web design, data science, and more. ",
      open: false
    },
    {
      question: "What support is provided to students during the job search process?",
      answer: "Our dedicated career services team offers comprehensive support, including resume writing assistance, interview preparation workshops, networking events, and access to our network of industry partners. ",
      open: false
    },
    {
      question:
        "What is the Pay After Placement model?",
      answer: "The Pay After Placement model at MakeNow Academy allows students to enroll in our programs with an upfront fee of 20,000. The remaining tuition of 80,000 is deferred until after the student secures a job in their field of study. ",
      open: false
    },
    {
      question:
        "How does Pay After Placement benefit students?",
      answer: "Pay After Placement removes financial barriers to education, allowing students to begin their studies with a lower upfront investment. Students can invest in their education with confidence, knowing they will only pay the remaining tuition once they secure a job. ",
      open: false
    },
    {
      question:
        "Is there any interest or additional fees associated with the Pay After Placement model?",
      answer: "No, there are no interest charges or additional fees associated with the Pay After Placement model. Students pay the remaining tuition amount of 80,000 only after they start earning a specified minimum income in their new job. ",
      open: false
    },
    {
      question:
        "What happens if a student does not secure a job after completing the program?",
      answer: "In the rare event that a student does not secure a job within the specified timeframe after completing the program, they are not required to pay the remaining tuition fees. Our priority is the success of our students, and we provide ongoing support to help them find employment opportunities. ",
      open: false
    },
    {
      question:
        "Is the Pay After Placement model available for all programs at MakeNow Academy?",
      answer: "The Pay After Placement model may be available for select programs. Please check with our admissions team for more information on eligibility and program availability. ",
      open: false
    },
  ]);

  const toggleFAQ = index => {
    setFaqs(prevFaqs =>
      prevFaqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : false // Close other FAQs
      }))
    );
  };

  return (
    <div className="App">
    
      <Header />
      <Navbar/>
      
      <div className="faqs">
        {faqs.map((faq, index) => (
          <Faqs faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
      <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img2} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  );
}
