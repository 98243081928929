import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Aws = () => {
  return (
    <div className='passbox'>
      <NavBar />
      <div className="pass">
        <h2>What is AWS?</h2>
        <p>
          AWS is a collection of remote computing services, including a portfolio of web services and software products that together make up a cloud computing platform, also called Amazon Web Services. AWS is designed to make web-scale computing easier for developers. It provides a broad set of cloud computing services, including compute, storage, databases, networking, content delivery, deployment, management, mobile, developer tools, and application services. Using AWS, developers can deploy web applications and services that scale in minutes, run on demand, and are paid only for the usage of the resources.
        </p>
        <h2>What is AWS used for?</h2>
        <p>
          AWS is architected to be the most flexible and secure cloud computing environment available today. Our core infrastructure is built to satisfy the security requirements for the military, global banks, and other high-sensitivity organizations.
        </p>
        <h2>What are the pre-requisites to learn AWS?</h2>
        <ul>
          <li>Fundamental knowledge of operating systems like Windows, Linux, etc.</li>
          <li>Fundamental knowledge of Networking and Virtualization.</li>
          <li>Knowledge of any programming language.</li>
          <li>Fundamental command on coding (because certain auxiliary software may need it).</li>
          <li>Overview of Public and Private Cloud.</li>
        </ul>
        <h2>What are some common uses of AWS?</h2>
        <p>Top 7 AWS Applications</p>
        <ul>
          <li>Storage and Backup.</li>
          <li>Big Data.</li>
          <li>Netflix.</li>
          <li>Enterprise IT.</li>
          <li>Social Networking.</li>
          <li>Mobile Apps.</li>
          <li>Websites.</li>
          <li>Gaming.</li>
          
        </ul>
        
        <h2>Why AWS is so popular?</h2>
        <p>Scalable and Adaptable</p>
        <p>In fact, AWS is great for building a business from the bottom as it provides all the tools necessary for companies to start up with the cloud. For existing companies, Amazon provides low-cost migration services so that your existing infrastructure can be seamlessly moved over to AWS.</p>
        <h2>Why AWS is used?</h2>
        <p>AWS is designed to allow application providers, ISVs, and vendors to quickly and securely host your applications – whether an existing application or a new SaaS-based application. You can use the AWS Management Console or well-documented web services APIs to access AWS's application hosting platform.</p>
        <h2>Why is AWS so successful?</h2>
        <p>To my knowledge, AWS provides a lot of services for the internet that are easy to use, either via console or coding level, to integrate into applications. The greatest thing is that users only need to pay for used services. I mean, they provide "Pay as you go" facilities. No upfront payment is needed.</p>
        <h2>Why is AWS better than others?</h2>
        <p>What Amazon Web Services is, is a cloud platform that is used by internet start-up companies with huge market-shares, and some fortune 500 companies. The cloud acts to host websites and store data across a network of servers, and the AWS does this for a whole host of companies. The network has some very interesting features for business. The most interesting is the ability for companies to start up and scale to whatever size as required, rather than having to make some huge capital expenditure that sits there half-used. The cloud can be used in a variety of ways, from renting out computer cycles, to renting out hard-disk space.</p>
        <h2>How has AWS Become So Successful?</h2>
        <ul>
          <li>Security: AWS provides a secure and durable platform that provides end-to-end security and storage.</li>
          <li>Experience: The skills and infrastructure management born from Amazon’s many years of experience can be very valuable.</li>
          <li>Flexibility: It allows users to select the operating systems, language, database, and other services as per their requirements.</li>
          <li>Easy to use: AWS lets you host your applications quickly and securely, regardless of whether it’s an existing or new application.</li>
          <li>Scalable: The applications you use can be scaled up or down, depending on your requirements.</li>
          <li>Cost savings: You only pay for the compute power, storage, and other resources that you use, without any long-term commitments.</li>
          <li>Scheduling: This enables you to start and stop AWS services at predetermined times.</li>
          <li>Reliability: AWS takes multiple backups at servers at multiple physical locations.</li>
        </ul>
        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Aws;
