import React,{ useState} from 'react';
import './home.css';
import Img from '../img/img3 (1).png';
import { Link } from 'react-router-dom';
import Img1 from '../img/logo.png';
import { Homee } from './homee';

 
export const Robocenter = () => {
  return (
    <div className="background-video">
       <Homee/>
      <br/>
      <div className="boxxx">
        <div className="page">
        <img  src={Img1} alt='img' className='imagee'/>

          <div className='main-heading-video '>
            <div className='home-video' id='box1'>
              <div className='main-sub-heading welcome-animation'>
                <p>The IT Career of Your Dreams Starts Here!</p>
                <h1 className="word"></h1>
              </div>
            </div>
          </div>
          <div className="popup-container show">
            <div className="experience-message">
              <div className='frsherorexpbox '>
                <p className="message-textt">Are you <br/>
                  <Link to='/fresherscardpages'><button>Fresher</button></Link><br/> or <br/> <Link to='/experience'><button>Experienced</button></Link>  ?</p>
              </div>
            </div>
            <img
              src={Img}
              alt="Robot Image"
              className="robot-image move-to-center"
            />
          </div>
        </div>
      </div>
      
    </div>
  );
};