import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const clinical = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Clinical SAS?</h2>
        <p>
          Clinical SAS is the deployment of the tools and programming language offered by the SAS Institute in the analysis and reporting of clinical trials data, and enables experts to use SAS programming in the analysis of clinical trials and their processes; assessment, management and transformation of data from clinical trials and reporting the data after validation for an extensive range of clinical trials to improve and enhance the level of statistical validity and robustness of results.
        </p>
        <h2>What does SAS stand for in clinical research?</h2>
        <p>
          Statistical Analysis System
        </p>
        <p>
          Statistical Analysis System, or SAS, has been the industry standard for data management and analytics of large volumes of data. It's a very flexible platform that provides users various means to manipulate, analyze, process, and report on data.
        </p>
        <h2>What is SAS Used for?</h2>
        <p>
          Imagine AstraZeneca managing the production, provenance, current location, destination, or pharmacy customer for every pill produced. Imagine Lufthansa managing the air tickets and flight data for millions of customers every day. Or perhaps Honda tracking the inventory for every vehicle produced and for sale around the world – plus data on which parts are included in which models at any given location; in case of a product recall? These are all examples of things that can be done with SAS. AstraZeneca, Lufthansa, and Honda are all companies that use SAS in their everyday business. SAS is an invaluable tool for business in the 21st century.
        </p>
        <h2>Why Clinical SAS Analytics</h2>
        <p>
          With the increasing demand for medicines, there is a building pressure of pharmaceutical research and development and using computational tools is automating many of these processes and making them more efficient. Clinical Research is one such growing industry that requires trained specialists in Bio-IT.
        </p>
        <h2>How SAS is used in clinical trials</h2>
        <p>
          The Role of SAS in Clinical Research and Clinical Trial Management. Statistical Analysis System, or SAS, has been the industry standard for data management and analytics of large volumes of data. It's a very flexible platform that provides users various means to manipulate, analyze, process, and report on data.
        </p>
        <h2>SAS Application in Clinical Trials</h2>
        <p>
          FDA defines clinical trials as voluntary research studies conducted in people, that are designed to answer specific questions about the safety and/or effectiveness of drugs, vaccines, other therapies, or new ways of using existing treatments. The data generated is the most important and confidential to a clinical trial project as the fate of the drug, whether or not the drug will reach the pharmacy shelf, depends on it. A clinical trial is conducted in multiple centers and a large amount of data is generated which can be difficult to manage. Once the data is clean, a clinical study report should be written for submitting to regulatory authorities for product approval. Statistical Analysis Software (SAS) is a bio statistical tool used to manage and generate tables, listings and graphs for clinical study reports etc. Let’s discuss the basics of the SAS tool and its use in clinical domain.
        </p>
        <p>
          SAS is a multi-domain application used to generate reports, tables, listings, 2D and 3D graphs, charts, etc. The major domains of SAS are SAS®/Clinical, SAS®/Finance, SAS®/Banking, SAS®/Advanced Analytics, SPSS®/Market Research, Excel/Analytics, Minitab etc. In clinical domain, SAS plays a major role in data analysis and thus in preparing the clinical study report. 
        </p>
        <p>
          SAS environment has three windows namely, editor window, log window and output window. The editor window is used to write and execute the SAS program. The log window displays the backend execution of the program and error messages. The output window displays the output of the executed program. Every statement in SAS ends with semicolon, “;”.
        </p>
        <p>
          The core functions of SAS in clinical trials include:
        </p>
        <ul>
          <li>Protocol Design and Study Start-Up</li>
          <li>Patient and Investigator Recruitment</li>
          <li>Clinical Trial Management</li>
          <li>Clinical Data Management</li>
          <li>Data Analysis</li>
          <li>Clinical Supplies</li>
          <li>Regulatory  and  Safety compliance</li>
          <li>Regulatory submissions</li>
        </ul>
        <p>
          SAS play a major role starting from defining the clinical study to till regulatory submission. SAS can be integrated with the clinical trial management systems (CTMS) like Oracle Clinical. The data integration process starts with the extraction of data (Data Importing). Once the data is imported to SAS environment, the data is filtered and data cleaning takes place. Once the data is clean (error free), SAS tables are generated for analysis of the data. The clinical study report is compiled using data generated in the SAS environment. SAS also plays a role in protocol development, randomization process, IVRS/IWRS procedure, CRF designing, adverse event reporting etc.
        </p>
        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default clinical;
