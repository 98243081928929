import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Sql = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is SQL SERVER?</h2>
        <p>
          SQL Server is a relational database management system, or RDBMS, developed and marketed by Microsoft. Similar to other RDBMS software, SQL Server is built on top of SQL, a standard programming language for interacting with relational databases. SQL Server is tied to Transact-SQL, or T-SQL, the Microsoft’s implementation of SQL that adds a set of proprietary programming constructs. SQL Server works exclusively on the Windows environment for more than 20 years. In 2016, Microsoft made it available on Linux. SQL Server 2017 became generally available in October 2016 that ran on both Windows and Linux.
        </p>

        <h2>What is a SQL Server for beginners?</h2>
        <p>
          SQL Server is a relational database management system (RDBMS) developed and marketed by Microsoft. As a database server, the primary function of the SQL Server is to store and retrieve data used by other applications.
        </p>

        <h2>What is SQL Server and why it is used?</h2>
        <p>
          Microsoft SQL Server is a relational database management system (RDBMS) that supports a wide variety of transaction processing, business intelligence, and analytics applications in corporate IT environments.
        </p>

        <h2>What are the benefits of SQL Server?</h2>
        <p>
          MS SQL Server Advantages:
          <ul>
            <li>Easy Installation: All the Microsoft products are easy to install with the one-click installation procedure and readable GUI with lots of instructions for the layman.</li>
            <li>Improved Performance</li>
            <li>Security</li>
            <li>Multiple Editions and Price Variations</li>
            <li>Excellent Data Restoration and Recovery Mechanism</li>
          </ul>
        </p>

        <h2>Why do we use SQL Server?</h2>
        <p>
          SQL is used to communicate with a database. According to ANSI (American National Standards Institute), it is the standard language for relational database management systems. SQL statements are used to perform tasks such as updating data on a database or retrieving data from a database.
        </p>

        <h2>Why SQL Server Always On works</h2>
        <p>
          It allows you to create a group of databases which failover together as a unit from one replica/instance of SQL Server to another replica/instance of SQL Server in the same availability group.
        </p>

        <h2>Why use SQL Server instead of MySQL?</h2>
        <p>
          MySQL supports programming languages like C++, Java and has running support for Perl, TCL, and Haskell. SQL Server supports programming languages like C++, Java, Ruby, Visual Basic, Delphi, R. MySQL needs less amount of operational storage space. SQL Server needs a large amount of operational storage space.
        </p>

        <h2>How SQL Server works</h2>
        <p>
          SQL Server follows a table structure based on rows, allowing the connection of data and functions while maintaining the data's security and consistency. Checks in the relational model of the server work to ensure that database transactions are processed consistently.
        </p>

        <h2>How does SQL Server store data internally?</h2>
        <p>
          Have you ever wondered how SQL server physically stores table data internally? Well, data in tables is stored in row and column format at the logical level, but physically, it stores data in something called data pages. A data page is the fundamental unit of data storage in SQL Server and it is 8KB in size.
        </p>

        <h2>How SQL Server Always On works</h2>
        <p>
          In the SQL Server Always On, the Primary replica sends transaction log to secondary databases for all databases defined in the availability group. We can have two types of data commit modes. We might be interested to know the internals of SQL Server Always On.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Sql;
