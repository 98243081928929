import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Sap = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is SAP FICO?</h2>
        <p>
          SAP FICO stands for FI (Financial Accounting) and CO (Controlling). SAP FICO is the important module of ERP and both FI and CO modules store the complete financial transaction data of an organization.
        </p>

        <h2>What exactly SAP FICO module is</h2>
        <p>
          SAP FI is the important module of all SAP implementations. SAP FI (Financial Accounting) is used for external reporting i.e., Balance sheet, Profit and Loss statements. SAP FICO Module integrates with various other SAP Modules such as MM (Materials Management), SD (Sales and Distribution), PP (Production Planning), PM (Plant Maintenance), and PS (Project Systems). SAP FI (Financial Accounting) Module receives postings from various other modules such as Materials Management, Sales and Distribution, and Human Resource) through integration. All accounting-relevant transactions which are made in Logistics (LO) are posted real-time to Financial Accounting by automatic account determination. This data can also be passed on to Controlling (CO). At least one company code must be created in SAP to implement the FI module.
        </p>

        <h2>What is the scope of SAP FICO</h2>
        <p>
          The scope of SAP FICO is huge. A big number of industries are working on the ECC model and use FICO for the functional part of their business process. As a result, lots of FICO consultants and end-users work on the implementation and support jobs.
        </p>

        <h2>Why is SAP so widely used?</h2>
        <p>
          It provides information across all departments in real-time. SAP ERP system provides control on different business processes. The centralized system enhances productivity, provides better inventory management, endorses quality, decreases raw material cost, effective HR management, reduces expenses, and enhances profits.
        </p>

        <h2>How it works</h2>
        <p>
          SAP FICO allows an organization to store a complete version of their financial transaction data. Specifically, the purpose of SAP FICO is to help companies generate and manage financial statements for analysis and reporting, as well as to aid in effective business planning and decision-making.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Sap;
