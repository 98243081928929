import React from 'react';
import { Home } from "./components/home";
import { Fresherscardpages } from "./components/fresherscardpages";
import { Experience } from "./components/experience";
import { Timeline } from "./components/timeline";
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import { WebDevelopment } from  './components/WebDevelopment';
import { Dotnet } from  './components/dotnet';
import { Dataengineer } from  './components/dataengineer';
import { Python } from  './components/python';
import { Software } from  './components/software';
import { Digitalmarketing } from  './components/digitalmarketing';
import { Sas } from  './components/sas';
import { Dataanalytics } from  './components/dataanalytics';
import { Etltesting } from  './components/etltesting';
import { Mainframes } from  './components/mainframes';
import { Robocenter } from './components/robocenter';
import { Contactus } from './components/contactus';
import { Firstpage } from './components/firstpage';
import  Blog  from './components/blog';
import { Referandearn } from './components/referandearn';
import  Aboutus  from './components/aboutus';
import { Login } from './components/login';
import Angular from './components/angular';
import Aws from './components/aws';
import Mysql from './components/mysql';
import Powerbi from './components/powerbi';
import Etl from './components/etl';
import Pythonsel from './components/pythonsel';
import Pythonn from './components/pythonn';
import Sap from './components/sap';
import Sql from './components/sql';
import Web from './components/web';
import Java from './components/java';
import Data from './components/data';
import Digital from './components/digital';
import Devops from './components/devops';
import Cyber from './components/cyber';
import Clinical from './components/clinical';
import Azure from './components/azure';
import Banking from './components/banking';
import Faq from './components/faq';
import Faqs from './components/faqs';



function App() {
  return (
    <div>
    <BrowserRouter>
     <Routes>
      <Route path="/home" element={<Home/>} />
      <Route path="/" element={<Firstpage/>} />
      <Route path="/fresherscardpages" element={<Fresherscardpages/>} />
      <Route path="/experience" element={<Experience/>} />
      <Route path="/timeline" element={<Timeline/>} />
      <Route path="/webDevelopment" element={<WebDevelopment/>} />
      <Route path="/dotnet" element={<Dotnet/>} />
      <Route path="/dataengineer" element={<Dataengineer/>} />
      <Route path="/python" element={<Python/>} />
      <Route path="/software" element={<Software/>} />
      <Route path="/digitalmarketing" element={<Digitalmarketing/>} />
      <Route path="/sas" element={<Sas/>} />
      <Route path="/dataanalytics" element={<Dataanalytics/>} />
      <Route path="/etltesting" element={<Etltesting/>} />
      <Route path="/mainframes" element={<Mainframes/>} />
      <Route path="/powerbi" element={<Powerbi/>} />
      <Route path="/robocenter" element={<Robocenter/>} />
      <Route path="/Blog" element={<Blog/>} />
      <Route path="/Referandearn" element={<Referandearn/>} />
      <Route path="/aboutus" element={<Aboutus/>} />
      <Route path="/angular" element={<Angular/>} />
      <Route path="/aws" element={<Aws/>} />
      <Route path="/pythonsel" element={<Pythonsel/>} />
      <Route path="/pythonn" element={<Pythonn/>} />
      <Route path="/java" element={<Java/>} />
      <Route path="/mysql" element={<Mysql/>} />
      <Route path="/etl" element={<Etl/>} />
      <Route path="/sap" element={<Sap/>} />
      <Route path="/web" element={<Web/>} />
      <Route path="/sql" element={<Sql/>} />
      <Route path="/data" element={<Data/>} />
      <Route path="/digital" element={<Digital/>} />
      <Route path="/devops" element={<Devops/>} />
      <Route path="/cyber" element={<Cyber/>} />
      <Route path="/clinical" element={<Clinical/>} />
      <Route path="/azure" element={<Azure/>} />
      <Route path="/banking" element={<Banking/>} />
      <Route path="/Login" element={<Login/>} />
      <Route path="/Faq" element={<Faq/>} />
      <Route path="/Faqs" element={<Faqs/>} />

     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;

