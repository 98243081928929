import React, { useEffect, useRef, useState } from 'react';
import './timeline.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { Homee } from './homee';
import { Footer } from './footer';


export const Timeline = () => {
  const canvasRef = useRef(null);

  

  useEffect(() => {
    AOS.init();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let paused = true;
    let rafid = 0;

    const mouse = { x: 0, y: 0 };

    const options = {
      speed: 0.05, // Adjusted for slower motion
      density: 8,
      radius: 600,
    };

    const targets = [
      [29, 32, 48, 68],
      [29, 33, 38]
    ];

    const dotColors = [
      ['#13669b', 'rgba(19, 102, 155, 0.3)', 'rgba(19, 102, 155, 0.08)'],
      ['#7dd317', 'rgba(113, 222, 15, 0.3)', 'rgba(91, 164, 22, 0.12)'],
    ];

    const lines = [
      new Line(0, canvas.offsetHeight - 100, '#4789a3', options, mouse),
      new Line(0, canvas.offsetHeight - 100, '#a0d59c', options, mouse)
    ];

    function Line(y, height, color, options, mouse) {
      this.color = color;
      this.options = options;
      this.mouse = mouse;
      this.height = height;
      this.dots = [];
      this.y = y;
      this.points = [];

      this.reset = function (x) {
        this.points = [];
        for (let y = this.y; y < this.height; y += this.options.density)
          this.points.push(new Point(x, y, this.color));
      }

      this.update = function () {
        for (let i = 0; i < this.points.length; i++)
          this.points[i].update(this.mouse, this.options);
      }

      function Point(x, y) {
        this.y = y;
        this.x = x;
        this.base = { x: x, y: y };

        this.update = function (mouse, options) {
          const dx = this.x - mouse.x;
          const dy = this.y - mouse.y;
          const alpha = Math.atan2(dx, dy);
          const d = options.radius / Math.sqrt(dx * dx + dy * dy);

          this.y += Math.cos(alpha) * d + (this.base.y - this.y) * options.speed;
          this.x += Math.sin(alpha) * d + (this.base.x - this.x) * options.speed;
        }
      }
    }

    function drawCircle(p, r, color) {
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(p.x, p.y, r, 0, 2 * Math.PI, true);
      ctx.closePath();
      ctx.fill();
    }

    function drawLine(p1, p2) {
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();
      ctx.closePath();
    }

    function redraw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < 2; i++) {
        const points = lines[i].points;

        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = lines[i].color;
        ctx.moveTo(points[15].x, points[15].y);

        for (let j = 15; j < points.length - 2; j++) {
          const point = points[j];
          const xc = (points[j + 1].x + point.x) / 2;
          const yc = (points[j + 1].y + point.y) / 2;

          ctx.quadraticCurveTo(point.x, point.y, xc, yc);
        }
        ctx.stroke();
        ctx.closePath();

        ctx.lineWidth = 1.2;
        ctx.strokeStyle = dotColors[i][2];
        for (let j = 0; j < lines[i].dots.length; j++) {
          const dot = lines[i].dots[j];
          const id = targets[i][j];
          const dot2 = [
            (lines[i].points[id].x + lines[i].points[id + 1].x) / 2,
            (lines[i].points[id].y + lines[i].points[id + 1].y) / 2,
          ];

          const p1 = { x: dot[0], y: dot[1] };
          const p2 = { x: dot2[0], y: dot2[1] };

          drawLine(p1, p2);
          drawCircle(p1, 3, dotColors[i][0]);

          drawCircle(p2, 11, dotColors[i][1]);
          drawCircle(p2, 5.5, dotColors[i][0]);
        }
      }
    }

    function animate() {
      rafid = requestAnimationFrame(animate);

      lines[0].update();
      lines[1].update();

      redraw();
    }

    function toggle(run) {
      if (run === undefined)
        toggle(!paused);
      else if (!!run && paused) {
        paused = false;
        animate();
      } else if (!!!run) {
        paused = true;
        cancelAnimationFrame(rafid);
      }
      return true;
    }

    function init() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);

      return true;
    }

    function InitDots() {
        const tl = document.querySelector('.timeline');
        const top = tl.querySelector('h2').offsetHeight;
      
        lines[0].dots = [];
        let y = top;
        tl.querySelector('article:first-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[0].dots.push([figure.offsetWidth + 20, y + 20]);
          y += figure.offsetHeight;
        });
      
        lines[1].dots = [];
        y = top;
        tl.querySelector('article:last-of-type').querySelectorAll('figure').forEach((figure) => {
          lines[1].dots.push([canvas.width - figure.offsetWidth - 20, y + 20]);
          y += figure.offsetHeight;
        });
      }

    function OnResize() {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const wasPaused = paused;
      toggle(false);

      lines[0].reset(canvas.offsetWidth / 2 - 15);
      lines[1].reset(canvas.offsetWidth / 2 + 15);

      InitDots();

      toggle(!wasPaused);
    }

    function toggleAnimation() {
      toggle(!paused);
    }

    init();
    animate();

    window.addEventListener('mousemove', (e) => {
      if (e.offsetX) {
        mouse.x = e.offsetX;
        mouse.y = e.offsetY;
      } else if (e.layerX) {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
      } else {
        mouse.x = e.pageX - canvas.offsetLeft;
        mouse.y = e.pageY - canvas.offsetTop;
      }
    });

    window.addEventListener('resize', OnResize);

    return () => {
      // Cleanup event listeners or perform any cleanup if needed
      window.removeEventListener('mousemove', () => {});
      window.removeEventListener('resize', OnResize);
      cancelAnimationFrame(rafid);
      AOS.refresh();
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  return (
    <section id="timeline">
      <Homee/>
      <br/>
      <br/>
      <br/>
    
      <div className="timeline" data-aos="fade-up">
        <canvas id="cvs3" ref={canvasRef}></canvas>
       <div data-aos="fade-up">
        <h2>Fresher</h2>
       <article>
          <figure data-aos="fade-right">
            <figcaption>Web Design</figcaption>
            <h6>using Html CSS JS</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero. Ut ut libero in nibh convallis sollicitudin. Maecenas ante erat, lacinia commodo pretium vel, ultricies eget nibh. Duis et felis lectus. Donec orci libero, auctor eget sodales at, euismod venenatis nibh.
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>Web Development</figcaption>
            <h6>With modern technologies</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero.
            </p>
          </figure>
          <figure data-aos="fade-right">
            <figcaption>Search Engine Op.</figcaption>
            <h6>With Free & Paid Plugin</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero.
            </p>
          </figure>
          
        </article>
        <article>
        
          <figure data-aos="fade-left">
            <figcaption>Graphic & WEB</figcaption>
            <h6>Learn Today</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero.
            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>Mobile Friendly</figcaption>
            <h6>with touch screen</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ipsum pulvinar, blandit neque in, ornare libero.
            </p>
          </figure>
          <figure data-aos="fade-left">
            <figcaption>Retina Ready Display</figcaption>
            <h6>Supported all devices</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto, molestiae, perferendis. Iusto nam libero doloribus veritatis magnam sequi error, obcaecati, porro atque quo unde iure voluptate officiis eos nostrum, sint. Corporis ipsam necessitatibus repudiandae, beatae odio perferendis provident consequuntur inventore natus deleniti asperiores animi ducimus assumenda possimus eos, cum sunt.
            </p>
          </figure>
        </article>
       </div>
        <br style={{ clear: 'both' }} />
      </div>
      <Footer/>
    </section>
  );
};


