import React, { useEffect } from 'react';
import './firstpage.css';
import { Link } from 'react-router-dom';

export const Homee = () => {
  useEffect(() => {
    const handleScroll = () => {
      // Close the menu when the user scrolls
      const menuCheckbox = document.getElementById('menu');
      if (menuCheckbox.checked) {
        menuCheckbox.checked = false;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className='bodyyyyyyy d-flex flex-row justify-content-center'>
        <nav className="main-nav">
          <input id="menu" type="checkbox" />
          <label htmlFor="menu">Menu</label>
          <ul className="menu-items">
            <li>
              <Link to="/aboutus">
                <span>About Us</span>
                <i className="fa-sharp fa-solid fa-user-group fa-bounce" style={{ fontSize: "24px" }}></i>
              </Link>
            </li>
            <li>
              <Link to="/Fresherscardpages">
                <span>Courses</span>
                <i className="fa-sharp fa-solid fa-book fa-bounce" style={{ fontSize: "24px" }}></i>
              </Link>
            </li>
            <li>
              <a href="/Referandearn">
                <span>Refer & Earn</span>
                <i className="fa-solid fa-hand-holding-dollar fa-bounce" style={{ fontSize: "24px" }}></i>
              </a>
            </li>
            <li>
              <Link to="/blog">
                <span>Blog</span>
                <i className="fa-brands fa-blogger fa-bounce" style={{ fontSize: "24px" }}></i>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Home</span>
                <i className="fa-sharp fa-solid fa-house fa-bounce" style={{ fontSize: "24px" }}></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
