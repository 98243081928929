import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const banking = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is SAS in banking?</h2>
        <p>
          SAS is a paid software system that provides high performance analytics for banking research. Organizations can identify, investigate, and govern the life cycle of their inquiries.
        </p>
        <h2>What is SAS Used For</h2>
        <p>
          Imagine AstraZeneca managing the production, provenance, current location, destination, or pharmacy customer for every pill produced. Imagine Lufthansa managing the air tickets and flight data for millions of customers every day. Or perhaps Honda tracking the inventory for every vehicle produced and for sale around the world – plus data on which parts are included in which models at any given location; in case of a product recall? These are all examples of things that can be done with SAS. AstraZeneca, Lufthansa, and Honda are all companies that use SAS in their everyday business. SAS is an invaluable tool for business in the 21st century.
        </p>
        <h2>Why do banks use SAS?</h2>
        <p>
          SAS helps to reduce the risk. SAS helps to improve customer relationships and enhance the customer experience to restore consumer/market confidence. SAS helps to reduce operational losses and combat financial crime in order to accurately provision capital and ensure customer confidence.
        </p>
        <h2>SAS banking analytics—why it’s the solution banks need</h2>
        <p>
          SAS banking analytics can help you resolve several compliance-related issues. It can meet the needs of banks and other large corporations, making it better equipped to handle the large volume of data stored in their databases. SAS analytics uses technology like AI, machine learning, and cloud computing to help you optimize certain data collection and analysis processes to make compliance more efficient. Besides optimizing data collection procedures, banking analytics from SAS can optimize reporting procedures. You can create an infrastructure that merges data modeling, measuring, and reporting to better manage risk and regulatory management. SAS analytics platforms support compliance for most regulatory risks, including regulatory capital, and liquidity risk. They can reduce the length of analytics cycles, improving operational efficiency. By speeding up processing time, we can also reduce the cost of compliance. Additionally, data management becomes more efficient because it’s much easier for research teams to store data and derive useful information from it. Along with improving regulation, banks can also improve governance with analytics. SAS analytics provides a risk profile that covers the entire network of the organization. This ensures a level of transparency, which is difficult to manage using other means. Better transparency makes it easy to meet regulatory compliance demands and manage internal risk, which can avert potential disasters.
        </p>
        <h2>Why do banks still use SAS?</h2>
        <p>
          SAS helps to take effective decision. And every decision they make that helps them to reduce non-performing assets indirectly generates a return on their investment. With SAS, the banks have greater confidence that they are making reliable decisions and the system performance has been excellent.
        </p>
        <h2>Is SAS used in banking?</h2>
        <p>
          One of the most highly rated banks in the world, Standard Chartered Bank uses SAS to meet stress-testing requirements, as well as calculate the impairment cost of a crisis and the resulting impacts on the bank's income statement and balance sheet.
        </p>
        <h2>How is SAS used in banking?</h2>
        <p>
          SAS is a paid software system that provides high performance analytics for banking research. Organizations can identify, investigate, and govern the life cycle of their inquiries.
        </p>
        <h2>Why choose SAS for banking analytics?</h2>
        <p>
          Banking as we know it is disappearing, and the entire financial ecosystem is undergoing radical change. Digitalization, fintech, regtech, open banking, data privacy, and regulatory compliance technologies are all playing a part. SAS believes that surviving banks will be hyper intelligent, AI-driven organizations that can provide personalized, trusted customer experiences, as well as meet risk and compliance mandates. SAS delivers proven value, and we can help you unlock AI’s vast potential for your digital transformation. With SAS, your future is certain.
        </p>
        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default banking;
