import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const Pythonn = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is Python and why it is used?</h2>
        <p>
          Python is a computer programming language often used to build websites and software, automate tasks, and conduct data analysis. Python is a general-purpose language, meaning it can be used to create a variety of different programs and isn't specialized for any specific problems.
          Python is a must for students and working professionals to become great Software Engineers, especially when working in the Web Development Domain. I will list down some of the key advantages of learning Python:
          <ul>
            <li>Python is Interpreted: Python is processed at runtime by the interpreter. You do not need to compile your program before executing it.</li>
            <li>Python is Interactive: You can actually sit at a Python prompt and interact with the interpreter directly to write your programs.</li>
            <li>Python is Object-Oriented: Python supports Object-Oriented style or technique of programming that encapsulates code within objects.</li>
            <li>Python is a Beginner's Language: Python is a great language for beginner-level programmers and supports the development of a wide range of applications.</li>
          </ul>
        </p>

        <h2>What Can Python Do?</h2>
        <p>
          We can achieve a lot by using Python as a programming language. Here’s a list of things we can do with Python:
          <ul>
            <li>Create web applications on a server.</li>
            <li>Create workflows along with software.</li>
            <li>Read and modify database systems.</li>
            <li>Solve complex problems and handle big data.</li>
            <li>Create rapidly-developed production-ready software.</li>
          </ul>
        </p>

        <h2>Why Python?</h2>
        <p>
          Python is often used as a support language for software developers, for build control and management, testing, and many other ways.
          Python is used in various fields including Machine Learning and AI, Web Development, Data Analytics, Game Development, IoT, Application Development, and Game Development.
          Python is easy to read, learn, and write. It has an English-like syntax and is easy to maintain. It also has a broad standard library and massive library support.
        </p>

        <h2>What are the advantages of using Python?</h2>
        <p>
          The advantages of using Python include:
          <ul>
            <li>It's easy to read, learn, and write.</li>
            <li>Improved Productivity.</li>
            <li>Interpreted Language.</li>
            <li>Dynamic Semantics.</li>
            <li>Free and Open-Source.</li>
            <li>Massive Library Support.</li>
          </ul>
        </p>

        <h2>What are the disadvantages of Python?</h2>
        <p>
          The disadvantages of Python include:
          <ul>
            <li>Python is slower than C or C++.</li>
            <li>Not good for mobile development.</li>
            <li>Memory Consumption.</li>
            <li>Limitations with database access.</li>
            <li>Runtime Errors.</li>
          </ul>
        </p>

        <h2>Python's features include</h2>
        <p>
          Python's features include:
          <ul>
            <li>Easy-to-learn.</li>
            <li>Easy-to-read.</li>
            <li>Easy-to-maintain.</li>
            <li>Broad standard library.</li>
            <li>Interactive Mode.</li>
            <li>Portable.</li>
            <li>Extendable.</li>
            <li>Databases.</li>
            <li>GUI Programming.</li>
            <li>Scalable.</li>
          </ul>
        </p>

        <h2>How is Python used?</h2>
        <p>
          Python is used for various purposes such as creating web applications, workflows, database systems, solving complex problems, and creating software.
        </p>

        <h2>Can Python be used to make apps?</h2>
        <p>
          Python can be used for Android App Development even though Android doesn't support native Python development. This can be done using various tools that convert Python apps into Android Packages that can run on Android devices.
        </p>

        <h2>How Python is executed?</h2>
        <p>
          Python code is translated into intermediate code, which has to be executed by a virtual machine, known as the PVM, the Python Virtual Machine. This is a similar approach to the one taken by Java. There is even a way of translating Python programs into Java byte code for the Java Virtual Machine (JVM).
        </p>

        <h2>How does Python actually work?</h2>
        <p>
          Python is called an interpreted language. Python uses code modules that are interchangeable instead of a single long list of instructions that was standard for functional programming languages. The standard implementation of Python is called “cpython”. It is the default and widely used implementation of Python.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default Pythonn;
