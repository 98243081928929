import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import NavBar from './nav';
import { Footer } from './footer';
import './pass.css';

const DevOps = () => {
  return (
    <div className='passbox'>
      <NavBar /> 
      <div className="pass">
        <h2>What is DevOps?</h2>
        <p>
          DevOps is a combination of the fields of development and organization. It involves several practices, philosophies, technologies, and tools that help companies increase their ability to offer services and applications with improved products at a high pace. This velocity was earlier difficult to achieve with traditional methods of infrastructure management and software development. DevOps brings the two departments together, providing a responsive, accurate, and faster software delivery experience to customers.
        </p>

        <h2>Why is DevOps so important?</h2>
        <p>
          Now that we have defined DevOps, we must go beyond and analyze the reasons for companies to adopt this methodology.
        </p>
        <p>
          Before we discuss such motives, it might be interesting to point out that, according to DevOps.com, the number of companies that have incorporated DevOps has been increasing exponentially every year.
        </p>
        <p>
          The reasons that have contributed the most to DevOps’ development are:
        </p>
        <ol>
          <li><strong>Shorter development cycles that encourage innovation:</strong> The fact that both departments (development and operations) come together is an advantage when it comes to releasing new apps, products… It is generally known that the more innovative companies are, the higher their chances of outrunning the competition. Which is essential to increase significantly competitiveness?</li>
          <li><strong>More collaboration, better communication:</strong> Thanks to the union between both teams, productivity improves a lot. The DevOps culture is based on achieving the best performance in such a union, instead of worrying about individual objectives. As a result of both departments being fused, the process becomes more fluid since everyone is oriented towards a common goal. To ensure that your DevOps team reaches its best performance, it is necessary to create a transparency culture in which responsibilities are shared and immediate feedback is guaranteed.</li>
          <li><strong>Reduced deployment failures and faster time to recover:</strong> Most failures during development occur due to programming defects. Having a DevOps team will allow for more releases in shorter time spans. This way, it is easier and more likely to find possible defects in the code. For this same reason, and in case any problem must be solved, recovery will be quicker thanks to the knowledge and participation of all members during the development process.</li>
          <li><strong>Efficiency: Improved resource management:</strong> Increased efficiency helps speed up development and reduce coding defects and problems. Nowadays, some programs are capable of automating DevOps tasks, reducing, as a result, the need for manual labor. What does this mean? Simply put, that software engineers can concentrate more on the kind of tasks that cannot be automated. You’re probably wondering how you DevOps team could improve efficiency ratios. Luckily, we have the solution you’re looking for, contact our consulting team, they’ll be glad to assist you.</li>
        </ol>

        <h2>How DevOps Works</h2>
        <p>
          Under a DevOps model, development and operations teams are no longer “siloed.” Sometimes, these two teams are merged into a single team where the engineers work across the entire application lifecycle, from development and test to deployment to operations, and develop a range of skills not limited to a single function. In some DevOps models, quality assurance and security teams may also become more tightly integrated with development and operations and throughout the application lifecycle. When security is the focus of everyone on a DevOps team, this is sometimes referred to as DevSecOps.
        </p>
        <p>
          These teams use practices to automate processes that historically have been manual and slow. They use a technology stack and tooling which help them operate and evolve applications quickly and reliably. These tools also help engineers independently accomplish tasks (for example, deploying code or provisioning infrastructure) that normally would have required help from other teams, and this further increases a team’s velocity.
        </p>

        {/* Add a Link component to navigate to another page */}
        <Link to="/blog">
           <button className="button">
           <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
            Go to Blog
            </button>
        </Link>

      </div>
      <Footer />
    </div>
  );
};

export default DevOps;

