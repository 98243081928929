import React, { useState, useEffect} from 'react';
import Img from '../img/10798281_19362653.jpg'
import Img1 from '../img/26459420_future_of_work_concept_illustration.jpg'
import Img2 from '../img/4102879_971.jpg'
import Img3 from '../img/4559772_4426.jpg'
import Img4 from '../img/teenager-dressed-white-t-shirt-using-virtual-reality-glasses-with-graph-charts-numbers-lines-technology-concept.jpg'
import { Link } from 'react-router-dom';
import Navbar from './nav';
import { Footer } from './footer';
import axios from 'axios';
 
export const Experience = () => {
                const [showPopup, setShowPopup] = useState(false);
                const [brochureUrl, setBrochureUrl] = useState('');
              
                const togglePopup = (data) => {
                  setShowPopup(!showPopup);
                  setBrochureUrl(data);
                };
              
                const handleDownload = (event) => {
                  event.preventDefault();
                 console.log(brochureUrl)
                };
                const [formData, setFormData] = useState({
                  name: '',
                  contact: '',
                  year: '',
                  city: ''
              });
              
              const handleChange = (e) => {
                  setFormData({ ...formData, [e.target.name]: e.target.value });
              };
               
              const handleSubmit = (e) => {
                e.preventDefault();
                axios.post('https://api.makenow.ashishphotography.co.in/submit', formData)
                    .then(response => {
                        console.log(response.data);
                        // Handle success (e.g., show a success message)
                    })
                    .catch(error => {
                        console.error(error);
                        // Handle error (e.g., show an error message)
                    });
              };
  return (
    <div className='cardmarginnn'>
      <Navbar/>
      <br/>
     {/* <div>
     <Link to="/homee" >
      <div className='linkhover'>
      <i class="fa-solid fa-arrow-left backbutton"></i><snap className="backtext">Back</snap>
      </div>
      </Link>
     </div> */}
      {showPopup && (
                      <div className="popup">
                        <div className="popup-inner">
                          <h2>Brochure Download Form</h2>
                          <form onSubmit={handleSubmit}>
                              <label>
                                  Name:
                                  <input type="text" name="name" value={formData.name} onChange={handleChange} />
                              </label>
                              <label>
                                  Contact:
                                  <input type="text" name="contact" value={formData.contact} onChange={handleChange} />
                              </label>
                              <label>
                                  Year of Passout:
                                  <input type="text" name="year" value={formData.year} onChange={handleChange} />
                              </label>
                              <label>
                                  City:
                                  <input type="text" name="city" value={formData.city} onChange={handleChange} />
                              </label>
                              <button type="submit">Submit</button>
                              <button type="submit" onClick={handleDownload}>Download</button>

                          </form>
                          <button onClick={togglePopup}>Close</button>
                        </div>
                      </div>
                    )}     

      <div className='carddssubd d-flex flex-column justify-content-center align-items-center'>
      <div className='cardrow d-flex justify-content-center'>
        <div class="cardddd cols">
          <div class="tools">
            <div class="circle">
              <span class="red box"></span>
            </div>
            <div class="circle">
              <span class="yellow box"></span>
            </div>
            <div class="circle">
              <span class="green box"></span>
            </div>
          </div>
          <div class="cardddd__content">
            <img src={Img} alt="Image description"/>
            <h2  className='typing-demo'>SAS</h2>
            <p> "Dive into the world of data analytics with our SAS course. Unleash the power of Statistical Analysis System to master advanced
            data manipulation, analysis, and visualization techniques. Elevate your skill set and open doors to a realm of possibilities in the data-driven landscape."</p>
            <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
                          </div>
            </div>
        </div>
        <div class="cardddd cols">
          <div class="tools">
            <div class="circle">
              <span class="red box"></span>
            </div>
            <div class="circle">
              <span class="yellow box"></span>
            </div>
            <div class="circle">
              <span class="green box"></span>
            </div>
          </div>
          <div class="cardddd__content">
            <img src={Img1} alt="Image description"/>
            <h2  className='typing-demo'>Data Analytics</h2>
            <p>"Immerse yourself in the realm of data analytics to uncover trends and acquire insightful knowledge from complex datasets.
                This course will set you on the way to mastering the analytical skills necessary to thrive in the quickly evolving data-driven sectors."</p>
                <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
      
            </div>
            </div>
        </div>
        <div class="cardddd cols">
              <div class="tools">
                <div class="circle">
                  <span class="red box"></span>
                </div>
                <div class="circle">
                  <span class="yellow box"></span>
                </div>
                <div class="circle">
                  <span class="green box"></span>
                </div>
              </div>
              <div class="cardddd__content">
                <img src={Img2} alt="Image description"/>
                <h2  className='typing-demo'>ETL Testing</h2>
                <p> "Enroll in our ETL Testing course to unravel the complexities of data integration. Master the techniques to validate,
                    transform, and load data seamlessly, ensuring its accuracy and reliability for a robust and efficient data ecosystem."</p>
                    <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
                          </div>
                </div>
        </div>
      </div>
 
      <div className='cardrow d-flex flex-row justify-content-center row mt-3'>
        <div class="cardddd cols">
              <div class="tools">
                <div class="circle">
                  <span class="red box"></span>
                </div>
                <div class="circle">
                  <span class="yellow box"></span>
                </div>
                <div class="circle">
                  <span class="green box"></span>
                </div>
              </div>
              <div class="cardddd__content">
                <img src={Img3} alt="Image description"/>
                <h2  className='typing-demo'>Mainframes</h2>
                <p> "Discover the prowess of Mainframes in our showcase course designed for students aspiring to excel in enterprise computing.
                     Unleash your potential with hands-on experience in managing colossal data and become a proficient Mainframe specialist."</p>
                     <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
                          </div>
                </div>
        </div>
        <div class="cardddd cols">
              <div class="tools">
                <div class="circle">
                  <span class="red box"></span>
                </div>
                <div class="circle">
                  <span class="yellow box"></span>
                </div>
                <div class="circle">
                  <span class="green box"></span>
                </div>
              </div>
              <div class="cardddd__content">
                <img src={Img4} alt="Image description"/>
                <h2  className='typing-demo'>Power Bi</h2>
                <p>"Empower your academic journey with our Power BI course, designed for students eager to unlock the art of data visualization. From crafting insightful reports to building interactive dashboards, this course equips you with essential skills for a data-driven future."</p>
                <div>
                            <div className='linkto'> 
                            <button className='button2' onClick={() => togglePopup('1')}>Brochure</button>
                            
                            </div>
                            <div className='linktoo'>
                            <Link to="/webdevelopment"><button className='button2'>View course</button></Link>
                            </div>
                          </div>
                </div>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
};