import React,{useEffect,useState} from 'react'
import Img2 from '../img/Screenshot 2024-02-19 122521.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes } from '@fortawesome/free-solid-svg-icons'; 

export const Contactus = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [name, setname] = useState('');
    const [phone, setphone] = useState('');
    const [email, setemail] = useState('');
    const [subject, setsubject] = useState('');
    const [Message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    
    

    useEffect(() => {
        setShowContactForm(true);
    },[])

    const handleCloseContactForm = () => {
        setShowContactForm(false);
      };
    
      const handleONContactForm = () => {
        setShowContactForm(true);
      };



      const handlename = (e) => {
        setname(e.target.value);
      };

      const handleemail = (e) => {
        setemail(e.target.value);
      };

      

      const handlephone = (e) => {
        setphone(e.target.value);
      };

      const handleMessage = (e) => {
        setMessage(e.target.value);
      };


      const handleSubmit = (e) => {
        e.preventDefault();
      
        // Validation checks
        if (!name || !phone || !email || !Message ) {
          alert("Please fill in all the required fields.");
          return;
        }
      
        // Additional validation for email and phone
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          alert("Please enter a valid email address.");
          return;
        }
      
        if (phone.length < 10) {
          alert("Please enter a valid phone number ");
          return;
        }
      
        const data = {
          name: name,
          phone: phone,
          email: email,
          message: Message
        };
      
        sendMessage(data)
          .then(() => {
            setFormSubmitted(true);
            setname('');
            setphone('');
            setemail('');
            setMessage('');
          })
          .catch((error) => {
            console.error("Error sending message:", error);
          });
      };


      const sendMessage = (data) => {
        const telegram_bot_id = "6369286406:AAEpyc0_ys8ssEN-I4l4SVbAI6E_mRYUI7I";
        const chat_id = 1968186632;
        const message = `Name: ${data.name}\nPhone Number: ${data.phone}\nEmail: ${data.email}\nMessage: ${data.message}`;
    
        const settings = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
          },
          body: JSON.stringify({
            chat_id: chat_id,
            text: message,
          }),
        };
    
        return new Promise((resolve, reject) => {
          fetch(`https://api.telegram.org/bot${telegram_bot_id}/sendMessage`, settings)
            .then((response) => {
              console.log(response);
              resolve();
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        });
      };


      useEffect(() => {
        if (formSubmitted) {
          alert("Form submitted successfully!"); // You can replace this with your desired UI element or redirection logic
          setFormSubmitted(false); // Reset the form submission state
        }
      }, [formSubmitted]);

      const [showCard, setShowCard] = useState(true);
      const handleEnrollClick = () => {
        window.location.href = 'https://www.makenow.co.in/';
  

  return (
    <div>
        {showContactForm && (
      <div className='d-flex flex-row justify-content-center align-items-start contactusss'>
        <div class="container">
            <form class="form">

           <div className='d-flex mt-2'>
           <div class="descr">Contact us</div>    
           <div className='ml-auto' onClick={handleCloseContactForm}>
           <i className="fa-solid fa-xmark  closss"></i>
           </div>     
           </div>
           
       
              <div class="input">
                  <input required="" autocomplete="off" type="text" onChange={handlename}/>
                  <label for="name">Name</label>
              </div>

              <div class="input">
                  <input required="" autocomplete="off" name="email" type="text" onChange={handleemail}/>
                  <label for="email">E-mail</label>
              </div>

              <div class="input">
                  <input required="" autocomplete="off" name="Phone" type="text" onChange={handlephone}/>
                  <label for="Phone">Phone Number</label>
              </div>

              <div class="input">
                  <textarea required="" cols="30" rows="1" id="message" onChange={handleMessage}></textarea>
                  <label for="message">Message</label>
              </div>
              <button onClick={handleSubmit}>Send message →</button>
            </form>
        </div>
      </div>

          )}

          {!showContactForm && (
      <div className='contactbtnnn '>
      <button class="contactusbtn" onClick={handleONContactForm}>Contact Us</button>
      </div>
      )}
        <div className={`card-container ${showCard ? 'show' : 'hide'}` } data-aos="fade-up" >
            <div className="card">
                <div className="card-content">
                    <div className="image-container">
                        <img src={Img2} alt="Card Image" />
                    </div>
                    <button className="close-button" onClick={() => setShowCard(false)}>
                   <FontAwesomeIcon icon={faTimes} className="close-icon" />
                   </button>
                    <div className="text-container">
                        <h3>Explore our Job Guaranteed Program.</h3>
                        <p>Are you ready to kickstart your career in coding? Discover our comprehensive job guaranteed program designed to equip you with the skills and confidence needed to suceed in tech Industry.</p>
                    </div>
                </div>
                <div className="card-footer d-flex flex-row justify-content-center" >
                    <button className="book-now-button" onClick={handleEnrollClick}>Enroll</button>
                </div>
            </div>
        </div>
    </div>

  )
};
}
